import { createWebHistory, createRouter } from "vue-router";
import Home from "./components/Home.vue";
import Explorar from "./components/Explorar.vue";
import Login from "./components/Login.vue";
import Register from "./components/Register.vue";

import EventosView from "./components/Evento.vue";
import NProgress from  'nprogress'



//----------------------------------BOARD --------------------------
//Eventos
import EventosAdd from "./components/Board/Eventos/Add.vue";
import EventosOne from "./components/Board/Eventos/ListOne.vue";
import EventosAll from "./components/Board/Eventos/ListAll.vue";
import EventosActive from "./components/Board/Eventos/ListActive.vue";
import EventosUpdate from "./components/Board/Eventos/Update.vue";
import EventosDelete from "./components/Board/Eventos/Delete.vue";
//Categorias
import CategoriasAdd from "./components/Board/Categorias/Add.vue";
import CategoriasOne from "./components/Board/Categorias/ListOne.vue";
import CategoriasAll from "./components/Board/Categorias/ListAll.vue";
import CategoriasActive from "./components/Board/Categorias/ListActive.vue";
import CategoriasUpdate from "./components/Board/Categorias/Update.vue";
import CategoriasDelete from "./components/Board/Categorias/Delete.vue";
//Ingressos
import IngressosAdd from "./components/Board/Ingressos/Add.vue";
import IngressosOne from "./components/Board/Ingressos/ListOne.vue";
import IngressosAll from "./components/Board/Ingressos/ListAll.vue";
import IngressosActive from "./components/Board/Ingressos/ListActive.vue";
import IngressosUpdate from "./components/Board/Ingressos/Update.vue";
import IngressosDelete from "./components/Board/Ingressos/Delete.vue";
//Locais
import LocaisAdd from "./components/Board/Locais/Add.vue";
import LocaisOne from "./components/Board/Locais/ListOne.vue";
import LocaisAll from "./components/Board/Locais/ListAll.vue";
import LocaisActive from "./components/Board/Locais/ListActive.vue";
import LocaisUpdate from "./components/Board/Locais/Update.vue";
import LocaisDelete from "./components/Board/Locais/Delete.vue";

import linksrapidos from "./components/Linksrapidos.vue";


//pages static
import termosepoliticas from "./components/TermosPoliticas.vue";


// MINHA CONTA

// USER

import ContaIngressos from "./components/MinhaConta/User/meusingressos.vue"
import ImprimirIngresso from "./components/MinhaConta/User/printingresso.vue"


// lazy-loaded
const Profile = () => import("./components/Profile.vue")
const BoardAdmin = () => import("./components/BoardAdmin.vue")
const BoardModerator = () => import("./components/BoardModerator.vue")
const BoardUser = () => import("./components/BoardUser.vue")
const PathNotFound = () => import("./components/PathNotFound.vue")

const routes = [
  { path: '/:pathMatch(.*)*', component: PathNotFound },
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/explorar",
    component: Explorar,
  },

  {
    path: "/login",
    component: Login,
  },
{
    path: "/register",
    component: Register,
  },
  {
    path: "/conta",
    name: "profile",
    // lazy-loaded
    component: Profile,
  },
  {
    path: "/conta/ingressos",
    name: "ingressos",
    // lazy-loaded
    component: ContaIngressos,
  },
  {
    path: "/conta/imprimir/:id",
    name: "imprimir",
    // lazy-loaded
    component: ImprimirIngresso,
  },
  {
    path: "/admin",
    name: "admin",
    // lazy-loaded
    component: BoardAdmin,
  },
  {
    path: "/mod",
    name: "moderator",
    // lazy-loaded
    component: BoardModerator,
  },
  {
    path: "/user",
    name: "user",
    // lazy-loaded
    component: BoardUser,
  },
  {
    path: "/evento/:id",
    component: EventosView,
  },

//----------------------------------BOARD --------------------------


  //EVENTOS
  {
    path: "/eventos/active",
    alias: "/eventos/active",
    name: "eventos-active",
    component: EventosActive,
  },
  {
    path: "/eventos",
    alias: "/eventos",
    name: "eventos-all",
    component: EventosAll,
  },
  {
    path: "/eventos/:id",
    name: "eventos-one",

    component: EventosOne,
  },
  {
    path: "/eventos/add",
    name: "eventos-add",
    component: EventosAdd,
  },
  {
    path: "/eventos/update/:id",
    name: "eventos-update",
    component: EventosUpdate,
  },
  {
    path: "/eventos/delete/:id",
    name: "eventos-delete",
    component: EventosDelete,
  },

  //LOCAIS

  {
    path: "/locais/active",
    alias: "/locais/active",
    name: "locais-active",
    component: LocaisActive,
  },
  {
    path: "/locais",
    alias: "/locais",
    name: "locais-all",
    component: LocaisAll,
  },
  {
    path: "/locais/:id",
    name: "locais-one",

    component: LocaisOne,
  },
  {
    path: "/locais/add",
    name: "locais-add",
    component: LocaisAdd,
  },
  {
    path: "/locais/update/:id",
    name: "locais-update",
    component: LocaisUpdate,
  },
  {
    path: "/locais/delete/:id",
    name: "locais-delete",
    component: LocaisDelete,
  },

//CATEGORIAS
  {
    path: "/categorias/active",
    alias: "/categorias/active",
    name: "categorias-active",
    component: CategoriasActive,
  },
  {
    path: "/categorias",
    alias: "/categorias",
    name: "categorias-all",
    component: CategoriasAll,
  },
  {
    path: "/categorias/:id",
    name: "categorias-one",

    component: CategoriasOne,
  },
  {
    path: "/categorias/add",
    name: "categorias-add",
    component: CategoriasAdd,
  },
  {
    path: "/categorias/update/:id",
    name: "categorias-update",
    component: CategoriasUpdate,
  },
  {
    path: "/categorias/delete/:id",
    name: "categorias-delete",
    component: CategoriasDelete,
  },

//INGRESSOS


{
  path: "/ingressos/active",
  alias: "/ingressos/active",
  name: "ingressos-active",
  component: IngressosActive,
},
{
  path: "/ingressos",
  alias: "/ingressos",
  name: "ingressos-all",
  component: IngressosAll,
},
{
  path: "/ingressos/:id",
  name: "ingressos-one",

  component: IngressosOne,
},
{
  path: "/ingressos/add",
  name: "ingressos-add",
  component: IngressosAdd,
},
{
  path: "/ingressos/update/:id",
  name: "ingressos-update",
  component: IngressosUpdate,
},
{
  path: "/ingressos/delete/:id",
  name: "ingressos-delete",
  component: IngressosDelete,
},


//LINKS RAPIDOS

{
  path: "/o/:id",
  name: "links-rapidos",
  component: linksrapidos,
},

// PAGE STATIC

{
  path: "/termos-e-politicas",
  name: "termos-e-politicas",
  component: termosepoliticas,
},


];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

 router.beforeEach((to, from, next) => {

   const pagesblock = ['conta'];


   const authRequired = pagesblock.some(page => to.path.startsWith(page, 1));


   const loggedIn = localStorage.getItem('user');


   if (authRequired && !loggedIn) {
     next('/login');
   } else {
     next();
   }
 });

 router.beforeResolve((to, from, next) => {
  if (to.name) {
    NProgress.start()
  }
  next()
})

router.afterEach(() => {
  NProgress.done()

})

export default router;