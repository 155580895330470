import CategoriasService from '../services/categorias.service';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

export const categorias = {
  namespaced: true,
  state: initialState,
  actions: {
    create({ commit }, user) {
      return CategoriasService.add(user).then(
        response => {
          commit('AddSuccess', response);
          return Promise.resolve(response);
        },
        error => {
          commit('AddFailure');
          return Promise.reject(error);
        }
      );
    },

  },
  mutations: {
    AddSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    AddFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },

  }
};
