<template>
   <div class="container">
    <div class="header-nav header-nav-conta">
      <div class="container">
        <div class="header-nav-cover">
          <nav class="nav-menu-new">
            <ul class="nav-list nav-list-sub">
              <li :class="activepage === '/conta' ? 'active-page' : ''">
                <a href="/conta">Minha conta </a>
              </li>
              <li
                :class="activepage === '/conta/ingressos' ? 'active-page' : ''"
              >
                <a href="/conta/ingressos">MEUS INGRESSOS</a>
              </li>
             <!--  <li :class="activepage === '/conta/compras' ? 'active-page' : ''">
                <a href="/conta/compras">MINHAS COMPRAS</a>
              </li>
              <li :class="activepage === '/conta/adiliados' ? 'active-page' : ''">
                <a href="/conta/compras">AFILIADOS</a>
              </li>
              <li :class="activepage === '/conta/suporte' ? 'active-page' : ''">
                <a href="/conta/suporte">SUPORTE</a>
              </li> -->

            </ul>
          </nav>
    <!--       <a href="/dashboard/evento/register" class="btn btn-pink"
            ><span>CRIAR UM EVENTO</span></a
          > -->
        </div>
      </div>
    </div>

  </div>
</template>


<script>
export default {
  name: "Profile",
  data() {
    return {
      activepage: 1,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user.user;
    },
    currentpage(){
      return  this.$route.fullPath;
    }
  },
  mounted() {
    this.activepage  =  this.$route.path;



    if (!this.currentUser) {
      this.$router.push("/login");
    }
  },
};
</script>

<style scoped>
@media (max-width: 1006px) {

  .nav-list-sub{
    margin-right: 15px;
  }
}

.nav-list > li > a:after{
top: -5px!important;
}
</style>