import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import vClickOutside from "v-click-outside";
import { FontAwesomeIcon } from "./plugins/font-awesome";
import "../firebase/firebaseInit";
import Maska from "maska";
import NProgress from "nprogress";
import VueSweetalert2 from "vue-sweetalert2";
import "nprogress/nprogress.css";
import "../src/assets/css/sweetalert2.css";

NProgress.configure({
  template:
    '<div class="bar" role="bar"></div><div id="preloader2"><div id="status"><div class="spinnerad" role="spinner"></div></div></div>',
});

const options = {
  confirmButtonColor: '#d52b74',
  cancelButtonColor: '#ff7674',
};



const app = createApp(App)
  .use(NProgress)
  .use(VueSweetalert2, options)
  .use(router)
  .use(Maska)
  .use(vClickOutside)
  .use(store)
  .component("font-awesome-icon", FontAwesomeIcon);

app.mount("#app");
