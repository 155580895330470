import Axios from './Axios';

//import authHeader from './auth-header';

const API_URL = '/client/vendas';

class CompraSaveService {
  getAll() {
    return Axios.get(API_URL + "/all");
  }

  gethash(hash) {
    return Axios.get(API_URL + `/hash/${hash}`);
  }
  getAllAtivo(data) {
    return Axios.get(API_URL + "/all_ativo", data);
  }
  createCart(data) {

    return Axios.post(API_URL + "/ingressos", data);
  }

  update(id, data) {
    return Axios.put(API_URL + `/update/${id}`, data);
  }

  delete(id) {
    return Axios.delete(API_URL + `/delete/${id}`);
  }

  findByTitle(title) {
    return Axios.get(API_URL + `?title=${title}`);
  }
}

export default new CompraSaveService();