<template>
  <div class="container container-content">
    <div class="title-cover">
      <h2 class="dance-title">Login</h2>
    </div>
  </div>

  <div class="col-md-12">
    <div class="card card-container">
      <div class="titletop">
        <h5>Seja bem vindo</h5>
        <p>Entre com suas informações de usuário</p>
      </div>
      <div class="form-group" v-if="messageCallback">
        <div class="alert alert-success" role="alert">
          {{ messageCallback }}
        </div>
      </div>
      <!--   <img
        id="profile-img"
        src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
        class="profile-img-card"
      /> -->

      <Form @submit="handleLogin" :validation-schema="schema">
        <div class="form-group">
          <label for="email">Email</label>
          <Field name="email" type="text" class="form-control" />
          <ErrorMessage name="email" class="error-feedback" />
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <Field name="password" type="password" class="form-control" />
          <ErrorMessage name="password" class="error-feedback" />
        </div>

        <div class="form-group">
          <button class="btn btn-login btn-block" :disabled="loading">
            <span   v-if="loading">
              <span class="spinner-border spinner-border-sm"></span><br />
              Carregando
            </span>
            <span v-else>Login</span>
          </button>
        </div>
        <div class="form-group">
          <div v-if="message" class="alert alert-danger" role="alert">
            {{ message }}
          </div>
        </div>
        <router-link to="/resetpw" class="nav-link linkmore">
          Esqueceu a senha?
        </router-link>
        <router-link to="/register" class="nav-link linkmore">
          Não tem uma conta? Registre-se
        </router-link>

      </Form>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import "firebase/compat/auth";
import firebase from "firebase/compat/app";
import AuthDataService from "../services/auth.service";

export default {
  name: "Login",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      email: yup.string().required("O E-mail é obrigatório!"),
      password: yup.string().required("A senha é obrigatório!"),
    });

    return {
      loading: false,
      message: "",
      messageCallback: "",
      schema,
    };
  },
  computed: {
    loggedIn() {
      console.log(this.$store.state.auth.status.loggedIn);
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    this.messagepage();
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/conta");
    }
  },
  methods: {
    messagepage() {
      switch (this.$route.query.ms) {
        case "1":
          this.messageCallback = "Conta criada com sucesso!";
          break;
      }

      switch (this.$route.query.me) {
        case "1222":
          this.messageCallback =
            "Sua sessão expirou, por favor logue novamente!";
          break;
          case "1224":
          this.messageCallback =
            "Você ainda não verificou seu email!";
          break;
        case "1223":
          this.messageCallback =
            "Sessão inválida, é necessário entrar novamente.";
          break;
      }
    },
    handleLogin(user) {
      this.loading = true;
      this.message = "";
      firebase
        .auth()
        .signInWithEmailAndPassword(user.email, user.password) // THIS LINE CHANGED
        .then(async (data) => {
          var dataf = {
            data: data.user.multiFactor.user,
            accessToken: data.user.multiFactor.user.accessToken,
            user: {},
          };
          if(dataf.data.emailVerified){
            localStorage.setItem("user", JSON.stringify(dataf));

          await AuthDataService.getDatauser().then(
            (response) => {
              dataf.user = response.data.data;

              localStorage.setItem("user", JSON.stringify(dataf));

              this.$router.go("/conta");
              setTimeout(() => {
                this.loading = false;
              }, 1200);
            },
            (error) => {
              this.loading = false;

              this.content =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
          }else{
            const user = firebase.auth().currentUser;

user.sendEmailVerification()
  .then(() => {
    console.log('E-mail de verificação enviado.');
    this.message = "E-mail de verificação enviado.";

    this.loading = false;

  })
  .catch((error) => {
    console.log('Erro ao enviar o e-mail de verificação:', error.code);
    switch (error.code) {
            case "auth/too-many-requests":
              this.message = "Erro já foi enviado um E-mail de verificação!";
              break;
            default:
              this.message = "Erro Email de verificação: " + error.code;
              break;
          }
    this.loading = false;

  });
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          switch (error.code) {
            case "auth/invalid-email":
              this.message = "E-mail inválido";
              break;
            case "auth/user-not-found":
              this.message = "Nenhuma conta com esse e-mail foi encontrada";
              break;
            case "auth/wrong-password":
              this.message = "Senha incorreta";
              break;
            default:
              this.message = "As informações estão incorretas";
              break;
          }
        });
    },
  },
};
</script>

<style scoped>
.linkmore:hover {
  color: #000;
}

@media only screen and (max-width: 600px) {
  form {
    min-width: initial;
  }
}

input {
  color: #000 !important;
}

.cardblank {
  background-color: #fff;
  padding: 20px 0px;
  border-radius: 15px;
}
.cardblank h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000 !important;
}

label {
  display: block;
  margin-top: 10px;
  color: #000 !important;
}

.card-container.card {
  max-width: 650px !important;
  padding: 40px 40px;
  border-radius: 15px;
}
.title-cover h2 {
  color: #fff !important;
}
.card {
  background-color: #fff;

  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.btn-login {
  color: #fff !important;
  background-color: #ff0470 !important;
  border-color: #ff0470 !important;
}

.error-feedback {
  color: red;
}
</style>
