<template>
  <div id="app">
    <header v-if="noshowMENU" class="ticket-smile-header-fixed" id="header_site">
      <div
        class="nav-btn"
        :class="opnedMenu ? 'active' : ''"
        @click="opnedMenu = !opnedMenu"
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="container">
        <div class="row conference-header-row imgheight">
          <div class="col-md-3">
            <a href="/" class="logo"
              ><img src="/assets/images/logo1.png" alt="logo"
            /></a>
          </div>
          <div class="col-md-9">
            <nav class="nav-menu menu" :class="opnedMenu ? 'active' : ''">
              <ul class="nav-list">
                <li :class="currentpage == '/' ? 'active-page' : ''">
                  <a href="/">INICIO</a>
                </li>
                <!--   <li
                  :class="
                    currentpage.includes('/explorar') ? 'active-page' : ''
                  "
                >
                  <a href="/explorar">EXPLORAR EVENTOS</a>
                </li>

                <li
                  :class="currentpage.includes('/criar') ? 'active-page' : ''"
                >
                  <a href="/criar">PUBLICAR EVENTO</a>
                </li> -->
                <li
                  :class="
                    currentpage.includes('/conta/ingressos')
                      ? 'active-page'
                      : ''
                  "
                  v-if="currentUser"
                >
                  <a href="/conta/ingressos">MEUS INGRESSOS</a>
                </li>

                <li
                  :class="currentpage == '/login' ? 'active-page' : ''"
                  v-if="!currentUser"
                >
                  <a href="/login">ENTRAR</a>
                </li>
                <li
                  :class="currentpage == '/conta' ? 'active-page' : ''"
                  v-if="currentUser"
                >
                  <a href="/conta">MINHA CONTA </a>
                </li>
                <li v-if="currentUser">
                  <a href="#" @click="logOut()"
                    >SAIR
                    <font-awesome-icon
                      icon="sign-out-alt"
                      style="margin-left: 4px"
                  /></a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
    <div>
      <router-view />
    </div>

    <footer v-if="noshowFooter" class="dark-footer" id="footer_site">
      <div class="container">
        <div class="row">
          <div class="footer-cont col-12 col-sm-6 col-lg-6">
            <a href="/" class="logo"
              ><img src="/assets/images/logo1.png" height="60px" alt="logo"
            /></a>
            <p>Avenida padre cletus francis cox, 529 - Poços de Caldas MG</p>
            <ul class="footer-contacts">
              <li class="footer-phone">
                <!--     <i aria-hidden="true" class="fas fa-phone"></i>
               <a href="tel:+5535000000000">(35) 0000-0000</a> -->
              </li>
              <li class="footer-email">
                <a href="mailto:contato@ticketsmile.com.br"
                  >contato@ticketsmile.com.br</a
                >
              </li>
              <li class="footer-email">
                <a href="mailto:financeiro@ticketsmile.com.br"
                  >financeiro@ticketsmile.com.br</a
                >
              </li>
            </ul>
            <div class="footer-copyright">
              <a target="_blank" href="https://ticketsmile.com.br"
                >Ticket Smile -
              </a>
              © {{ getYear() }}. Todos os direitos reservados.
            </div>
          </div>
          <!--  <div class="footer-item-link col-12 col-sm-6 col-lg-6">
            <div class="footer-link">
              <h5>Social</h5>
              <ul class="footer-list">
                <li>
                  <a target="_blank" href="https://www.facebook.com/"
                    >Facebook</a
                  >
                </li>
                <li>
                  <a target="_blank" href="https://twitter.com/"
                    >Twitter</a
                  >
                </li>
                <li>
                  <a target="_blank" href="https://www.instagram.com/"
                    >Instagram</a
                  >
                </li>
                <li>
                  <a target="_blank" href="https://www.youtube.com">Youtube</a>
                </li>
              </ul>
            </div>
            <div class="footer-link">
              <h5>Institucional</h5>
              <ul class="footer-list">
                <li><a href="#about">Ajuda</a></li>
                <li><a href="#news">Termos de uso</a></li>
                <li><a href="#schedule">Política de privacidade</a></li>
                <li><a href="#schedule">Fale conosco</a></li>
                <li><a href="#schedule">Nossas Soluções</a></li>
                <li><a href="#schedule">Publicar Evento </a></li>
              </ul>
            </div>
          </div> -->
        </div>
        <p>Ticket Smile Comercio Virtual LTDA - 50.516.107/0001-65</p>

      </div>
    </footer>
  </div>
</template>



<script>
import "./assets/css/slick.min.css";
import "./assets/css/bootstrap-grid.css";
import "./assets/css/nice-select.css";
import "./assets/css/jquery.fancybox.css";
import "./assets/css/style.css";

export default {
  data() {
    return {
      opnedMenu: false,
    };
  },
  mounted() {
    this.activepage = this.$route.path;
  },

  computed: {
    currentpage() {
      return this.$route.fullPath;
    },
    noshowFooter() {
      const publicPages = ["/conta/imprimir/"];
      const authRequired = !publicPages.some((page) =>
        this.$route.path.startsWith(page)
      );


      return authRequired
    },
    noshowMENU() {
      const publicPages = ["/conta/imprimir/"];
      const authRequired = !publicPages.some((page) =>
        this.$route.path.startsWith(page)
      );


      return authRequired
    },
    currentUser() {
      if (this.$store.state.auth.user) {
        return this.$store.state.auth.user.user;
      }
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser["roles"]) {
        return this.currentUser["roles"].includes("ROLE_ADMIN");
      }

      return false;
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser["roles"]) {
        return this.currentUser["roles"].includes("ROLE_MODERATOR");
      }

      return false;
    },
  },
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.go("/login");
    },
    getYear() {
      const xmas = new Date();
      const year = xmas.getFullYear();

      if (year != "2022") {
        return "2022 - " + year;
      } else {
        return year;
      }
    },
  },
};
</script>
<style >
.logo {
  display: flex;
  width: 80%;
}

.imgheight img {
  height: 50px;
}


/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #29d;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* ==============
  NgLoader
===================*/
#nprogress .bar {
  background: #d52b74;
  position: fixed;
  z-index: 999999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
}

#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #d52b74, 0 0 5px #d52b74;
  opacity: 1;
  transform: rotate(3deg) translate(0px, -4px);
}
/* ==============
  Loader
===================*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #eff3f6;
  z-index: 9999999;
}

#preloader2 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #eff3f6;
  z-index: 9999999;
}
.input-color-change{
  border: 1px solid red!important;
}
#preloader3 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #eff3f6b7;
  border-radius: 20px;
  padding: 180px;
  z-index: 9999999;
}

#status {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}

.spinnerad {
  position: absolute;
  width: 78px;
  height: 78px;
  left: 50%;
  margin-left: -39px;
  margin-top: -39px;
}

.spinnerad:before {
  content: "";
  position: absolute;
  width: 45px;
  height: 45px;
  top: 50%;
  margin-top: -23px;
  left: 50%;
  margin-left: -23px;
  border-width: 2px 1px;
  border-style: solid;
  border-color: #d52b74 rgba(213, 42, 116, 0.3);
  border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  animation: spin 3.45s infinite;
  -o-animation: spin 3.45s infinite;
  -ms-animation: spin 3.45s infinite;
  -webkit-animation: spin 3.45s infinite;
  -moz-animation: spin 3.45s infinite;
}

.spinnerad:after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  top: 50%;
  margin-top: -6px;
  left: 50%;
  margin-left: -6px;
  background-color: #d52b74;
  border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  animation: pulse 6.9s infinite, borderPulse 6.9s infinite;
  -o-animation: pulse 6.9s infinite, borderPulse 6.9s infinite;
  -ms-animation: pulse 6.9s infinite, borderPulse 6.9s infinite;
  -webkit-animation: pulse 6.9s infinite, borderPulse 6.9s infinite;
  -moz-animation: pulse 6.9s infinite, borderPulse 6.9s infinite;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(1080deg);
    transform: rotate(1080deg);
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(1080deg);
  }
}

@keyframes pulse {
  0% {
    background-color: rgba(213, 42, 116, 0.2);
  }
  13% {
    background-color: rgba(213, 42, 116, 0.2);
  }
  15% {
    background-color: rgba(213, 42, 116, 0.902);
  }
  28% {
    background-color: rgba(213, 42, 116, 0.902);
  }
  30% {
    background-color: rgba(213, 42, 116, 0.2);
  }
  43% {
    background-color: rgba(213, 42, 116, 0.2);
  }
  45% {
    background-color: rgba(213, 42, 116, 0.902);
  }
  70% {
    background-color: rgba(213, 42, 116, 0.902);
  }
  74% {
    background-color: rgba(213, 42, 116, 0.2);
  }
  100% {
    background-color: rgba(213, 42, 116, 0.902);
  }
}

@-webkit-keyframes pulse {
  0% {
    background-color: rgba(213, 42, 116, 0.2);
  }
  13% {
    background-color: rgba(213, 42, 116, 0.2);
  }
  15% {
    background-color: rgba(213, 42, 116, 0.902);
  }
  28% {
    background-color: rgba(213, 42, 116, 0.902);
  }
  30% {
    background-color: rgba(213, 42, 116, 0.2);
  }
  43% {
    background-color: rgba(213, 42, 116, 0.2);
  }
  45% {
    background-color: rgba(213, 42, 116, 0.902);
  }
  70% {
    background-color: rgba(213, 42, 116, 0.902);
  }
  74% {
    background-color: rgba(213, 42, 116, 0.2);
  }
  100% {
    background-color: #d52a74e6;
  }
}

@keyframes borderPulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 white, 0 0 0 1px rgba(213, 42, 116, 0.8);
    box-shadow: 0 0 0 0 white, 0 0 0 1px rgba(213, 42, 116, 0.8);
  }
  40% {
    -webkit-box-shadow: 0 0 0 1px white, 0 0 0 2px rgba(213, 42, 116, 0.8);
    box-shadow: 0 0 0 1px white, 0 0 0 2px rgba(213, 42, 116, 0.8);
  }
  80% {
    -webkit-box-shadow: 0 0 0 3px #ffffff, 0 0 1px 3px rgba(213, 42, 116, 0.8);
    box-shadow: 0 0 0 3px #ffffff, 0 0 1px 3px rgba(213, 42, 116, 0.8);
  }
}

@-webkit-keyframes borderPulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 white, 0 0 0 1px rgba(213, 42, 116, 0.8);
    box-shadow: 0 0 0 0 white, 0 0 0 1px rgba(213, 42, 116, 0.8);
  }
  40% {
    -webkit-box-shadow: 0 0 0 1px white, 0 0 0 2px rgba(213, 42, 116, 0.8);
    box-shadow: 0 0 0 1px white, 0 0 0 2px rgba(213, 42, 116, 0.8);
  }
  80% {
    -webkit-box-shadow: 0 0 0 3px #ffffff, 0 0 1px 3px rgba(213, 42, 116, 0.8);
    box-shadow: 0 0 0 3px #ffffff, 0 0 1px 3px rgba(213, 42, 116, 0.8);
  }
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

</style>