import axios from "axios";
import NProgress from "nprogress";
import store from "../store";

const isproduction = true;
const API_URL = isproduction
  ? "https://srv1br.ticketsmile.com.br"
  : "http://localhost:5001/ticket-api-a6731/us-west1/expressApi_us_west1";
const loading = true;
// create a new axios instance
const instance = axios.create({
  baseURL: API_URL,
});

const check = store.state.auth;

if (check.status.loggedIn == false) {

  console.log("STATUS DESLOGADO");
}
const getClientIP = async () => {
  let clientIP = ""
 await axios.get('https://api.ipify.org/?format=json')
      .then(response => {
        clientIP = response.data.ip;
      })
      .catch(error => {
        console.error('Erro:', error);
      });

  return clientIP; // Substitua pelo IP real do cliente
};

// before a request is made start the nprogress
instance.interceptors.request.use(async (config) => {
  const clientIP = await getClientIP(); // Certifique-se de que a resposta contém essa informação


  // Adiciona o cabeçalho X-Forwarded-For à requisição
  if (!config.headers['X-Forwarded-For']) {
    config.headers['X-Forwarded-For'] = clientIP;
  }

  if (loading) {
    NProgress.start();
  }
  return config;
});

instance.interceptors.response.use(
  (response) => {
    if (response.status === 401) {
      localStorage.removeItem("user");
      window.location.replace("/login?me=1222");
    }

    console.log(response.status);

    if (loading) {
      setTimeout(() => {
        NProgress.done();

        NProgress.remove();
      }, 135);
    }
    return response;
  },
  (error) => {
    if (loading) {
      setTimeout(() => {
        NProgress.done();

        NProgress.remove();
      }, 155);
    }
    if (error.response.status === 401) {
      localStorage.removeItem("user");
      window.location.replace("/login?me=1222");
    }

    return Promise.reject(error);
  }
);

export default instance;
