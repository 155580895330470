<template>
  <div class="popupVue" v-show="open && type == 1">
    <div class="popup-inner">
      <div class="topo">

<h2 class="popup-title">{{title}} </h2>
<button class="popup-closed" @click=" closeComprar()"><i class="fa fa-times " ></i></button>
</div>
      <div class="popup-content">

        <slot />


      </div>
    </div>
  </div>

  <div class="popupVue2" v-show="open && type == 2">
    <div class="popup-inner2">
      <div class="topo">

<h2 class="popup-title2">{{title}}</h2>
<button class="popup-closed" @click=" closeCupom()"><i class="fa fa-times " ></i></button>
</div>
      <div class="popup-content2">

        <slot />


      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  methods:{
    closeComprar(){

      const el = document.body;
      el.classList.remove('noscroll');
      this.$emit('close')

    },
    closeCupom(){

/* const el = document.body;
el.classList.remove('noscroll'); */

this.$emit('close')

},
  },
  mounted(){
    const el = document.body;

   if(this.open){
    el.classList.add('noscroll');
   }

  }
};
</script>

<style >
.topo {
  padding: 20px 20px 0px 20px;
  color: #636466 ;
  border-bottom: 2px solid rgb(25 31 40 / 18%);
}
.noscroll {
  height: 100vh;
  overflow-y: hidden;
}

.popupVue {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popupVue2 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-inner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 70vw;
  background-color: #fff;
  margin: 2rem auto;
  border-radius: 15px;
}


.popup-inner2 {
  position: fixed;
    left: 0;
    right: 0;
    /* height: 250px; */
    width: 300px;
    max-width: 300px;
    margin: 2rem auto;
    border-radius: 15px;
}

.popup-content2 {


color: #636466 !important;
width: 100%;


}
.popup-content {


  color: #636466 !important;
  width: 100%;
  height: 90%;
  border-radius: 0px 0px 0px 15px;
}

.popup-closed {
  position: absolute;
  top: 0;

  right: 0;

  border: none;
  color: #636466 ;
  background-color: transparent;
  font-size: 25px;
  padding: 15px 30px;
}

.popup-title{

    color: #636466 !important;
}

.popup-title2{
    color: #636466 !important;
}
@media (max-width: 930px){


.popup-inner {


position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
max-width: 100%;
margin: 0;

border: none;

}
.popup-content {
    position: relative;
  color: #636466 !important;

  height: 92%;

}
.popup-inner{
  border-radius: 0px;
}
.popup-footer{
  display: flex;
    position: fixed;
    bottom: 10px;
    padding: 0px;


  }

  .popup-footer2{
  display: flex;
    position: absolute;
    bottom: 10px;
    padding: 0px;
    right: 10px;
    left: 10px;

  }
}

</style>