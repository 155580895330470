<template>
  <div>

<div class="headertext">
  <h4>Faça o pagamento até {{ gethora(hora_f) }}</h4>

</div>

    <div id="qrcodepixcontent" v-if="order_status == 'success'">
        <div>

        <div style="word-wrap: break-word; max-width: 450px">
            <h2>Pagamento recebido</h2>
        <p>
          Obrigado por comprar em nossa site. Você pode consultar o andamento de
          seu pedido pela página do mesmo.
        </p>
        <a href="#">Acessar pedido</a>
        </div>
      </div>

      </div>


      <div id="qrcodepixcontent" v-else>

<img v-if="keyqrcode"
  id="copy-code"
  style="cursor: pointer; display: initial; margin-right: 1rem"
  class="wcpix-img-copy-code"
  :src="'https://gerarqrcodepix.com.br/api/v1?brcode=' + keyqrcode"
  alt="QR Code"
/>

<p v-else>Erro ao gerar qrcode</p>

<div>
  <div style="font-size: 19px; margin-bottom: 0.5rem">
   <p> Valor do Pix:</p>
    <strong>R$ {{ valor }} </strong>
  </div>
  <div style="word-wrap: break-word; max-width: 450px">
    <small>Código de transação: </small><br />
    <input type="text"
      id="pixcodestr"
      style="font-size: 87.5%; color: #e83e8c; word-wrap: break-word"
      v-model="keycode"
      @input="inputtype(keyqrcode)"
      @click="copypix(keyqrcode)"
      />


    <br />
    <input type="text" id="pixcode" style="display: none" />
    <button class="copyButton" @click="copypix(keyqrcode)"><i  class="far fa-copy"></i> Clique aqui para copiar</button>
  </div>
</div>
</div>




    <p style="margin-top: 1rem">
      Caso já tenha feito o pagamento, verifique se foi confirmado na página de
      <a href="/conta/pedidos/" >detalhes do pedido</a>

    </p>
    <div class="buttonclass mb-10">
          <router-link to="/conta/ingressos">
            <button class="btn btn-pink btn-long">
              <span>VER INGRESSOS</span>
            </button></router-link
          >
        </div>
  </div>
</template>

<script>
import translate from "./translation.json";
import "./assets/css/style.css";

export default {
  name: "PaymentSimple",

  data() {
    return {
      documento_required: false,
      translation: translate,

      isLoading: false,
      totalPix: 10,
      qrcode: "",
      keycode: "",
    };
  },
  mounted() {
    this.qrcode = this.qrCodePix;
    this.keycode = this.keyqrcode;
  },
  methods: {
    gethora(hora){
      const event = new Date(hora);
const options = { dateStyle: 'short', timeStyle: 'short' };
const date = event.toLocaleString('pt-BR', options);

return date
    },
    inputtype(code){
    this.keycode = code;

      return code
    },
    copypix(keycodee) {
    this.keycode = keycodee;

     /*  var copyText = keycodee; */
     /*  console.log(copyText);
      navigator.clipboard.writeText(copyText); */

      const input = document.getElementById("pixcodestr");

      if (this.isOS()) {
        let range = document.createRange();
        range.selectNodeContents(input);
        let selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        input.setSelectionRange(0, 999999);
        alert('Copiado com sucesso!')
      } else {
        input.select();
        alert('Copiado com sucesso!')

      }

      document.execCommand("copy");
      input.blur();
    },

    isOS() {
      return navigator.userAgent.match(/ipad|iphone/i);
    },

  },

  props: {
    qrCodePix: {
      required: true,
    },
    order_status: {
      required: true,
    },
    hora_f: {
      required: true,
    },
    keyqrcode: {
      required: true,
    },valor: {
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>



.copyButton{
  padding: 10px;
  color: #d52b74;
    border: 1px solid #d52b74;
    border-radius: 10px;
}

.copyButton i{

  color: #d52b74;

}
:root {
  --colorPrimaryText: #fff;
  --p-colorPrimaryHover: #ee2f81;
  --p-colorPrimaryActive: #a22058;
  --p-colorPrimaryAlpha20: hsla(334, 67%, 50%, 0.25);
  --p-colorPrimaryAlpha40: hsla(334, 67%, 50%, 40%);
  --p-colorPrimaryAlpha50: hsla(334, 67%, 50%, 50%);
  --p-colorPrimaryDisabled: #d9d9d9;
  --p-colorPrimaryDisabledText: #8d8d8d;
  --colorBackgroundText: #30313d;
  --p-colorBackgroundDivider: #f2f2f2;
  --p-colorBackgroundDisabled: #f2f2f2;
  --p-colorBackgroundDisabledDeemphasize05: #e6e6e6;
  --p-colorBackgroundDeemphasize03: #f7f7f7;
  --p-colorBackgroundDeemphasize05: #f2f2f2;
  --p-colorBackgroundDeemphasize10: #e6e6e6;
  --p-colorBackgroundDeemphasize15: #d9d9d9;
  --p-colorBackgroundDeemphasize20: #cccccc;
  --p-colorBackgroundLightenAbsolute05: #ffffff;
  --p-colorBackgroundContrastAlpha05: rgba(0, 0, 0, 0.05);
  --p-colorBackgroundContrastAlpha08: rgba(0, 0, 0, 0.08);
  --p-colorBackgroundContrastAlpha30: rgba(0, 0, 0, 0.3);
  --colorTextSecondary: #6d6e78;
  --colorTextPlaceholder: #77787e;
  --colorSuccessText: #fff;
  --colorDangerText: #fff;
  --colorWarningText: #30313d;
  --fontFamily: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  --fontSmooth: always;
  --fontVariantLigatures: normal;
  --fontVariationSettings: normal;
  --fontLineHeight: 1.45;
  --fontSizeBase: 1rem;
  --fontSizeSm: 0.93rem;
  --fontSizeXs: 0.875rem;
  --fontSize2Xs: 0.8125rem;
  --fontSize3Xs: 0.75rem;
  --fontSizeLg: 1.0625rem;
  --fontSizeXl: 1.125rem;
  --fontSize2Xl: 1.25rem;
  --fontWeightLight: 300;
  --fontWeightNormal: 400;
  --fontWeightMedium: 600;
  --fontWeightBold: bold;
  --colorPrimary: #d52b74;
  --colorBackground: #fff;
  --colorText: #30313d;
  --colorSuccess: #30b130;
  --colorDanger: #df1b41;
  --colorWarning: #f6e6b9;
  --colorIcon: var(--colorTextSecondary);
  --colorIconHover: var(--colorText);
  --colorIconCheckmark: var(--colorPrimaryText);
  --colorIconCardError: var(--colorDanger);
  --colorIconCardCvc: var(--colorIcon);
  --colorIconCardCvcError: var(--colorDanger);
  --colorIconChevronDown: var(--colorIcon);
  --colorIconChevronDownHover: var(--colorIconHover);
  --colorIconClose: var(--colorIcon);
  --colorIconCloseHover: var(--colorIconHover);
  --colorIconLoadingIndicator: var(--p-colorBackgroundContrastAlpha30);
  --colorIconMenu: var(--colorPrimary);
  --colorIconMenuHover: var(--colorIconMenu);
  --colorIconMenuOpen: var(--colorIconMenu);
  --colorIconPasscodeDevice: var(--colorIcon);
  --colorIconPasscodeDeviceHover: var(--colorIconHover);
  --colorIconPasscodeDeviceNotification: var(--colorPrimary);
  --colorIconRedirect: currentColor;
  --colorIconTab: var(--colorIcon);
  --colorIconTabHover: var(--colorText);
  --colorIconTabSelected: var(--colorPrimary);
  --colorIconTabMore: var(--colorIcon);
  --colorIconTabMoreHover: var(--colorText);
  --spacingUnit: 0.25rem;
  --spacingGridRow: var(--p-spacing3);
  --spacingGridColumn: var(--p-spacing3);
  --spacingTab: var(--p-spacing1);
  --spacingPickerItem: var(--p-spacing2);
  --spacingAccordionItem: var(--p-spacing2);
  --borderRadius: 5px;
  --focusBoxShadow: 0 0 0 3px var(--p-colorPrimaryAlpha20),
    0 1px 1px 0 var(--p-colorBackgroundContrastAlpha08);
  --focusOutline: 0;
  --p-spacingXs: 1px;
  --p-spacingSm: 2px;
  --p-spacing1: 0.25rem;
  --p-spacing2: 0.5rem;
  --p-spacing3: 0.75rem;
  --p-spacing4: 1rem;
  --p-spacing5: 1.25rem;
  --p-spacing6: 1.5rem;
  --p-spacing7: 1.75rem;
  --p-spacing8: 2rem;
  --p-spacing9: 2.25rem;
  --p-spacing10: 2.5rem;
  --p-logoLightDisplay: block;
  --p-logoDarkDisplay: none;
  --p-logoTabLightDisplay: block;
  --p-logoTabDarkDisplay: none;
  --p-logoTabSelectedLightDisplay: block;
  --p-logoTabSelectedDarkDisplay: none;
  --p-logoBlockLightDisplay: block;
  --p-logoBlockDarkDisplay: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.instrucoes ul li {
  list-style-type: none;
}
.a_to_button {
  cursor: pointer;
}
.a_to_button:hover {
  background: var(--c-acItemHoveredBackgroundColor);
  border-color: var(--c-acItemHoveredBorderColor);
  color: var(--c-acItemHoveredColor);
  outline: var(--c-acItemHoveredOutline);
}
.spacebottom {
  margin-bottom: 10px;
}
* {
  box-sizing: border-box;
}


.headertext {
  margin-top: 10px;
}

.headertext p,h4{
  color: #000!important;
}
.Error {
  margin-top: 0.25rem !important;
  color: #df1b41 !important;
  font-weight: 400 !important;
  font-size: 0.93rem !important;
}

.payment-simple {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  margin-bottom: 25px;
  -webkit-font-smoothing: antialiased;
  display: flex;
  justify-content: center;
  align-content: center;
}

form {
  width: 12vw;
  min-width: 500px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}

.hidden {
  display: none;
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
.submitForm button {
  background: var(--colorPrimary);
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}
.submitForm button:hover {
  filter: contrast(115%);
}
.submitForm button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}
.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}
.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: var(--colorPrimary);
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}
.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: var(--colorPrimary);
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
    min-width: initial;
  }
}

.Action {
  padding: var(--p-spacing1) calc(var(--p-spacing1) + var(--p-spacingSm));
  background-color: transparent;
  font-size: var(--fontSizeXs);
  font-weight: var(--fontWeightMedium);
  color: var(--colorPrimary);
  border-radius: min(5px, var(--borderRadius));
  transition: background 0.15s ease, color 0.15s ease, box-shadow 0.15s ease;
}

.Action:hover {
  background-color: var(--p-colorBackgroundContrastAlpha05);
}

.Action--tertiary {
  color: var(--colorText);
}

.Action--danger {
  color: var(--colorDanger);
}

.Action:disabled {
  color: var(--p-colorPrimaryDisabledText);
}

.Block {
  padding: var(--p-spacing3);
  border-radius: var(--borderRadius);
  background-color: var(--colorBackground);
  border: 1px solid var(--p-colorBackgroundDeemphasize10);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
}

.Button {
  padding: var(--p-spacing3);
  background-color: var(--colorPrimary);
  font-weight: var(--fontWeightMedium);
  color: var(--colorPrimaryText);
  border-radius: var(--borderRadius);
  transition: background 0.15s ease, color 0.15s ease, border 0.15s ease,
    box-shadow 0.15s ease;
}

.Button:hover {
  background-color: var(--p-colorPrimaryHover);
}

.Button:active {
  background-color: var(--p-colorPrimaryActive);
}

.Button:disabled {
  background-color: var(--p-colorPrimaryDisabled);
  color: var(--p-colorPrimaryDisabledText);
}

.CheckboxInput {
  background-color: var(--colorBackground);
  border-radius: min(5px, var(--borderRadius));
  transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease;
  border: 1px solid var(--p-colorBackgroundDeemphasize10);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
}

.CheckboxInput--checked {
  background-color: var(--colorPrimary);
  border-color: var(--colorPrimary);
}

.CodeInput {
  background-color: var(--colorBackground);
  border-radius: min(5px, var(--borderRadius));
  transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease,
    color 0.15s ease;
  border: 1px solid var(--p-colorBackgroundDeemphasize10);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
}

.Dropdown {
  border-radius: var(--borderRadius);
  border: 1px solid var(--p-colorBackgroundDeemphasize10);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 10px 24px rgba(0, 0, 0, 0.02);
}

.DropdownItem {
  background-color: var(--colorBackground);
  padding: var(--p-spacing2) var(--p-spacing3);
}

.Error {
  margin-top: var(--p-spacing1);
  color: var(--colorDanger);
  font-weight: var(--fontWeightNormal);
  font-size: var(--fontSizeSm);
}

.Icon {
  transition: fill 0.1s ease;
}

.Input,
.p-FauxInput {
  padding: var(--p-spacing3);
  background-color: var(--colorBackground);
  border-radius: var(--borderRadius);
  transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease,
    color 0.15s ease;
  border: 1px solid var(--p-colorBackgroundDeemphasize10);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
}

.Input--invalid {
  color: var(--colorDanger);
  border-color: var(--colorDanger);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02),
    0 0 0 1px var(--colorDanger);
}

.Input::placeholder,
.p-Input--placeholder {
  color: var(--colorTextPlaceholder);
}

.Input:disabled,
.p-Input--disabled {
  background-color: var(--p-colorBackgroundDisabled);
  color: var(--colorTextPlaceholder);
  border-color: var(--p-colorBackgroundDisabledDeemphasize05);
  box-shadow: none;
}

.InputCloseIcon {
  border-radius: min(5px, var(--borderRadius));
  transition: box-shadow 0.15s ease;
}

.Label {
  margin-bottom: var(--p-spacing1);
  font-size: var(--fontSizeSm);
  font-weight: var(--fontWeightNormal);
  transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1),
    opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}

.Label--resting {
  font-size: var(--fontSizeBase);
}

.Label--floating {
  font-size: var(--fontSizeXs);
  padding-bottom: max(var(--p-spacingSm), var(--p-spacing1));
  opacity: 0.8;
}

.Menu {
  padding: 0.375rem;
}

.MenuAction {
  background-color: var(--p-colorBackgroundContrastAlpha05);
  font-size: var(--fontSizeXs);
  font-weight: var(--fontWeightMedium);
  color: var(--colorPrimary);
  border-radius: min(8px, var(--borderRadius));
  transition: background 0.15s ease, box-shadow 0.15s ease;
}

.MenuAction--danger {
  color: var(--colorDanger);
}

.MenuAction:hover {
  background-color: var(--p-colorBackgroundContrastAlpha08);
}

.MenuIcon {
  border-radius: min(8px, var(--borderRadius));
  transition: background 0.15s ease, box-shadow 0.15s ease;
}

.MenuIcon:hover {
  background-color: var(--p-colorBackgroundContrastAlpha05);
}

.PasscodeShowIcon {
  outline-offset: -2px;
  border-radius: min(5px, var(--borderRadius));
  transition: box-shadow 0.15s ease;
}

.PasscodeCloseIcon {
  outline-offset: -2px;
  border-radius: min(5px, var(--borderRadius));
  transition: box-shadow 0.15s ease;
}

.PickerItem {
  padding: var(--p-spacing3);
  background-color: var(--colorBackground);
  border-radius: var(--borderRadius);
  transition: background 0.15s ease, box-shadow 0.15s ease, border 0.15s ease,
    color 0.15s ease;
  border: 1px solid var(--p-colorBackgroundDeemphasize10);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
}

.PickerItem--new {
  font-size: var(--fontSizeBase);
  font-weight: var(--fontWeightMedium);
  color: var(--colorPrimary);
}

.RedirectText {
  color: var(--colorTextSecondary);
}

.Link {
  color: var(--colorPrimary);
}

.Link:hover {
  text-decoration: underline;
  border-radius: min(5px, var(--borderRadius));
  transition: box-shadow 0.15s ease;
}

.SecondaryLink {
  color: var(--colorTextSecondary);
  border-radius: min(5px, var(--borderRadius));
  transition: box-shadow 0.15s ease;
}

.SecondaryLink:hover {
  text-decoration: underline;
}

.Switch {
  background-color: var(--p-colorBackgroundDeemphasize10);
  border: 1px solid var(--p-colorBackgroundDeemphasize10);
  transition: border 0.3s ease, background 0.2s ease, box-shadow 0.3s ease;
}

.Switch--checked {
  background-color: var(--colorSuccess);
  border-color: var(--colorSuccess);
}

.SwitchControl {
  fill: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s ease;
}

.Tab {
  padding: var(--p-spacing3);
  background-color: var(--colorBackground);
  border-radius: var(--borderRadius);
  font-size: var(--fontSizeXs);
  font-weight: var(--fontWeightMedium);
  color: var(--colorTextSecondary);
  transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease;
  border: 1px solid var(--p-colorBackgroundDeemphasize10);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
}

.Tab--selected {
  border-color: var(--p-colorPrimary);
  color: var(--colorPrimary);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02),
    0 0 0 1px var(--colorPrimary);
}

.TabLabel {
  padding-top: var(--p-spacing1);
  transition: color 0.1s ease;
}

.TermsText {
  color: var(--colorTextSecondary);
}

.TermsLink {
  color: var(--colorTextSecondary);
  text-decoration: underline;
  border-radius: min(5px, var(--borderRadius));
  transition: box-shadow 0.15s ease;
}

.AccordionItem {
  background-color: var(--colorBackground);
  border: 1px solid var(--p-colorBackgroundDeemphasize10);
  border-radius: var(--borderRadius);
  color: var(--colorTextSecondary);
  font-size: var(--fontSizeXs);
  font-weight: var(--fontWeightMedium);
  padding: var(--p-spacing4);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
}

.AccordionItem--selected {
  color: var(--colorPrimary);
}

.Action:focus-visible {
  outline: var(--focusOutline);
  box-shadow: var(--focusBoxShadow);
}

.Button:focus-visible {
  outline: var(--focusOutline);
  box-shadow: var(--focusBoxShadow);
}

.CheckboxInput:focus,
.p-CheckboxInput--focused {
  outline: var(--focusOutline);
  border-color: var(--p-colorPrimaryAlpha50);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02),
    var(--focusBoxShadow);
}

.CodeInput:focus,
.p-CodeInput--focused {
  outline: var(--focusOutline);
  border-color: var(--p-colorPrimaryAlpha50);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02),
    var(--focusBoxShadow);
}

.DropdownItem--highlight {
  background-color: var(--p-colorBackgroundDeemphasize03);
}

.Input:focus,
.p-Input--focused {
  outline: var(--focusOutline);
  border-color: var(--p-colorPrimaryAlpha50);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02),
    var(--focusBoxShadow);
}

.InputCloseIcon:focus-visible {
  outline: none;
  box-shadow: inset 0 0 0 2px var(--p-colorPrimaryAlpha40);
}

.MenuAction:focus-visible {
  outline: var(--focusOutline);
  box-shadow: var(--focusBoxShadow);
}

.MenuIcon:focus-visible {
  outline: var(--focusOutline);
  box-shadow: var(--focusBoxShadow);
}

.PasscodeShowIcon:focus-visible {
  outline: none;
  box-shadow: inset 0 0 0 2px var(--p-colorPrimaryAlpha40);
}

.PasscodeCloseIcon:focus-visible {
  outline: none;
  box-shadow: inset 0 0 0 2px var(--p-colorPrimaryAlpha40);
}

.PickerItem:focus-visible {
  outline: var(--focusOutline);
  border-color: var(--p-colorPrimaryAlpha50);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02),
    var(--focusBoxShadow);
}

.PickerItem:hover,
.p-PickerItem--hovered {
  background-color: var(--p-colorBackgroundDeemphasize03);
  border-color: var(--p-colorBackgroundDeemphasize15);
}

.PickerItem:hover:focus-visible {
  outline: var(--focusOutline);
  background-color: var(--p-colorBackgroundDeemphasize03);
  border-color: var(--p-colorPrimaryAlpha50);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02),
    var(--focusBoxShadow);
}

.PickerItem--selected {
  background-color: var(--colorBackground);
  border-color: var(--p-colorBackgroundDeemphasize10);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
}

.PickerItem--selected:focus-visible {
  background-color: var(--colorBackground);
  border-color: var(--p-colorBackgroundDeemphasize10);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
}

.PickerItem--selected:hover,
.p-PickerItem--selectedHovered {
  background-color: var(--colorBackground);
  border-color: var(--p-colorBackgroundDeemphasize10);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
}

.PickerItem--highlight {
  box-shadow: 0 0 0 1px var(--colorPrimary);
  border-color: var(--colorPrimary);
}

.PickerItem--highlight:hover,
.p-PickerItem--highlightHovered {
  box-shadow: 0 0 0 1px var(--colorPrimary);
  border-color: var(--colorPrimary);
}

.PickerItem--highlight:focus-visible {
  outline: var(--focusOutline);
  border-color: var(--colorPrimary);
  box-shadow: var(--focusBoxShadow), 0 0 0 1px var(--colorPrimary);
}

.PickerItem--highlight:hover:focus-visible {
  outline: var(--focusOutline);
  background-color: var(--p-colorBackgroundDeemphasize03);
  border-color: var(--colorPrimary);
  box-shadow: var(--focusBoxShadow), 0 0 0 1px var(--colorPrimary);
}

.PickerItem--disabled {
  background-color: var(--p-colorBackgroundDisabledDeemphasize05);
  border-color: var(--p-colorBackgroundDisabledDeemphasize05);
  color: var(--colorTextSecondary);
  box-shadow: none;
}

.PickerItem--disabled:hover,
.p-PickerItem--disabledHovered {
  background-color: var(--p-colorBackgroundDisabledDeemphasize05);
  border-color: var(--p-colorBackgroundDisabledDeemphasize05);
  color: var(--colorTextSecondary);
  box-shadow: none;
}

.SecondaryLink:focus-visible {
  outline: var(--focusOutline);
  box-shadow: var(--focusBoxShadow);
}

.Switch:focus-visible {
  outline: var(--focusOutline);
  box-shadow: var(--focusBoxShadow);
}

.Tab:hover {
  color: var(--colorText);
}

.Tab:focus {
  outline: var(--focusOutline);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02),
    var(--focusBoxShadow);
  border-color: var(--p-colorPrimaryAlpha50);
}

.Tab--selected:focus {
  border-color: var(--p-colorPrimary);
  box-shadow: var(--focusBoxShadow), 0 0 0 1px var(--colorPrimary);
}

.Tab--selected:hover {
  color: var(--colorPrimary);
}

.TermsLink:focus-visible {
  outline: var(--focusOutline);
  box-shadow: var(--focusBoxShadow);
}

.AccordionItem:hover,
.p-AccordionItem--hovered {
  color: var(--colorText);
}

.AccordionItem:focus-visible,
.p-AccordionItem--focused {
  outline: var(--focusOutline);
  border-color: var(--p-colorPrimaryAlpha50);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02),
    var(--focusBoxShadow);
}

.AccordionItem--selected:focus-visible {
  box-shadow: var(--focusBoxShadow), 0 0 0 1px var(--colorPrimary);
}

.AccordionItem--selected:hover,
.p-AccordionItem--selectedHovered {
  color: var(--colorPrimary);
}

#qrcodepixcontent {
  display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #f8f8f8;
    border-radius: 8px;
    padding: 1rem;
    align-content: flex-start;
    flex-wrap: nowrap;
}

@media only screen and (max-width: 600px) {
  #qrcodepixcontent {
    display: block;
  }
}
</style>