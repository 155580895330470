import Axios from './Axios';
//import authHeader from './auth-header';

const API_URL = '/client/pagamento';

class FinalizarService {
  getAll() {
    return Axios.get(API_URL + "/all");
  }

  get(id) {
    return Axios.get(API_URL + `/${id}`);
  }

  checkFinalizar(data) {

    return Axios.post(API_URL + "/check", data);
  }

  getAllAtivo(data) {


    return Axios.get(API_URL + "/all_ativo", data);
  }
  create(data) {
    return Axios.post(API_URL + "/add", data);
  }

  simular(data) {
    console.log(data)
    return Axios.post(API_URL + "/simular", data);
  }

  update(id, data) {
    return Axios.put(API_URL + `/update/${id}`, data);
  }

  delete(id) {
    return Axios.delete(API_URL + `/delete/${id}`);
  }

  findByTitle(title) {
    return Axios.get(API_URL + `?title=${title}`);
  }

  //==================================


  enviarPagamentoPix(data) {
    return Axios.post(API_URL +`/finalizar/pix`, data);
  }
  enviarPagamentoCartao( data) {
    return Axios.post(API_URL +`/finalizar/cartao`, data);

  }

  FinalizarVenda(data) {
    return Axios.post(API_URL + "/finalizarvenda", data);
  }

}

export default new FinalizarService();
