<template>
    <v-row align="center" class="list px-3 mx-auto">
      <v-col cols="12" md="8">
        <v-text-field v-model="title" label="Search by Title"></v-text-field>
      </v-col>

      <v-col cols="12" md="4">
        <v-btn small @click="searchTitle">
          Search
        </v-btn>
      </v-col>

      <v-col cols="12" sm="12">
        <v-card class="mx-auto" tile>
          <v-card-title>Eventos</v-card-title>

          <v-data-table
            :headers="headers"
            :items="eventos"
            disable-pagination
            :hide-default-footer="true"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="editEventos(item.id)">mdi-pencil</v-icon>
              <v-icon small @click="deleteEventos(item.id)">mdi-delete</v-icon>
            </template>
          </v-data-table>

          <v-card-actions v-if="eventos.length > 0">
            <v-btn small color="error" @click="removeAllEventos">
              Remove All
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </template>

  <script>
    import eventosDataService from "../../../services/eventosData.service";
  export default {
    name: "eventos-all",
    data() {
      return {
        eventos: [],
        title: "",
        headers: [
          { text: "Title", align: "start", sortable: false, value: "title" },
          { text: "Description", value: "description", sortable: false },
          { text: "Status", value: "status", sortable: false },
          { text: "Actions", value: "actions", sortable: false },
        ],
      };
    },
    methods: {
      retrieveEventos() {
        eventosDataService.getAll()
          .then((response) => {
            this.eventos = response.data.map(this.getDisplayEventos);
            console.log(response.data);
          })
          .catch((e) => {
            console.log(e);
          });
      },

      refreshList() {
        this.retrieveEventos();
      },

      removeAllEventos() {
        eventosDataService.deleteAll()
          .then((response) => {
            console.log(response.data);
            this.refreshList();
          })
          .catch((e) => {
            console.log(e);
          });
      },

      searchTitle() {
        eventosDataService.findByTitle(this.title)
          .then((response) => {
            this.eventos = response.data.map(this.getDisplayEventos);
            console.log(response.data);
          })
          .catch((e) => {
            console.log(e);
          });
      },

      editEventos(id) {
        this.$router.push({ name: "eventos-details", params: { id: id } });
      },

      deleteEventos(id) {
        eventosDataService.delete(id)
          .then(() => {
            this.refreshList();
          })
          .catch((e) => {
            console.log(e);
          });
      },

      getDisplayEventos(eventos) {
        return {
          id: eventos.id,
          title: eventos.title.length > 30 ? eventos.title.substr(0, 30) + "..." : eventos.title,
          description: eventos.description.length > 30 ? eventos.description.substr(0, 30) + "..." : eventos.description,
          status: eventos.published ? "Published" : "Pending",
        };
      },
    },
    mounted() {
      this.retrieveEventos();
    },
  };
  </script>

  <style>
  .list {
    max-width: 750px;
  }
  </style>