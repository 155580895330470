import Axios from './Axios';


const API_URL = 'client/vendas';

class VendasService {
  getAll() {
    return Axios.get(API_URL + "/all");
  }

  FinalizarVenda(data) {
    return Axios.post(API_URL + "/finalizarvenda", data);
  }

  checkoutCard() {
    return Axios.post(API_URL + "/checkout/cartao");
  }
  checkoutPix() {
    return Axios.post(API_URL + "/checkout/pix");
  }

  get(id) {
    return Axios.get(API_URL + `/${id}`);
  }

  checkCupom(data) {

    return Axios.post(API_URL + "/cupons", data);
  }

  getAllAtivo(data) {


    return Axios.get(API_URL + "/all_ativo", data);
  }
  create(data) {
    return Axios.post(API_URL + "/add", data);
  }

  update(id, data) {
    return Axios.put(API_URL + `/update/${id}`, data);
  }

  delete(id) {
    return Axios.delete(API_URL + `/delete/${id}`);
  }

  findByTitle(title) {
    return Axios.get(API_URL + `?title=${title}`);
  }

  simular(data) {
    console.log(data)
    return Axios.post(API_URL + "/simular", data);
  }
  cepFind(data) {
    return Axios.get("/cep/" + data);
  }
}

export default new VendasService();