import { createStore } from "vuex";
import { auth } from "./auth.module";
import { categorias } from "./categorias.module";
import { locais } from "./locais.module";

const store = createStore({
  modules: {
    auth,
    locais,
    categorias,
  },
});

export default store;
