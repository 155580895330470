<template>
 <div>
    <div class="container">
        <Form @submit="handlecategorias" :validation-schema="schema">
        <div v-if="!successful">
          <div class="form-group">
            <label for="name">Nome</label>
            <Field name="name" type="text" class="form-control" />
            <ErrorMessage name="name" class="error-feedback" />
          </div>


          <div class="form-group">
            <button class="btn btn-primary btn-block" :disabled="loading">
              <span
                v-show="loading"
                class="spinner-border spinner-border-sm"
              ></span>
              Criar
            </button>
          </div>
        </div>
      </Form>
    </div>

</div>
  </template>


<script>
  import { Form, Field, ErrorMessage } from "vee-validate";
  import * as yup from "yup";

  export default {
    name: "categorias-add",
    components: {
      Form,
      Field,
      ErrorMessage,
    },
    data() {
      const schema = yup.object().shape({
        name: yup.string().required("name is required!"),
      });

      return {
        loading: false,
        message: "",
        schema,
      };
    },

    methods: {
      handlecategorias(user) {
        this.message = "";
      this.successful = false;
     // this.loading = true;

      this.$store.dispatch("categorias/create", user).then(
        (data) => {
          this.message = data.message;
          this.successful = true;
          this.loading = false;
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.successful = false;
          this.loading = false;
        }
      );
      },
    },
  };
  </script>

  <style>
  .submit-form {
    max-width: 300px;
  }
  </style>