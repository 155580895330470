<template>
 <div>
    <div class="container">
        <Form @submit="handlelocais" :validation-schema="schema">
        <div v-if="!successful">
          <div class="form-group">
            <label for="Name">Nome</label>
            <Field name="Name" type="text" class="form-control" />
            <ErrorMessage name="Name" class="error-feedback" />
          </div>
          <div class="form-group">
            <label for="Cep">Cep</label>
            <Field name="Cep" type="text" class="form-control" />
            <ErrorMessage name="Cep" class="error-feedback" />
          </div>
          <div class="form-group">
            <label for="Rua">Rua</label>
            <Field name="Rua" type="text" class="form-control" />
            <ErrorMessage name="Rua" class="error-feedback" />
          </div>
          <div class="form-group">
            <label for="Numero">Numero</label>
            <Field name="Numero" type="text" class="form-control" />
            <ErrorMessage name="Numero" class="error-feedback" />
          </div>
          <div class="form-group">
            <label for="Bairro">Bairro</label>
            <Field name="Bairro" type="text" class="form-control" />
            <ErrorMessage name="Bairro" class="error-feedback" />
          </div>
          <div class="form-group">
            <label for="Cidade">Cidade</label>
            <Field name="Cidade" type="text" class="form-control" />
            <ErrorMessage name="Cidade" class="error-feedback" />
          </div>
          <div class="form-group">
            <label for="Estado">Estado</label>
            <Field name="Estado" type="text" class="form-control" />
            <ErrorMessage name="Estado" class="error-feedback" />
          </div>
          <div class="form-group">
            <button class="btn btn-primary btn-block" :disabled="loading">
              <span
                v-show="loading"
                class="spinner-border spinner-border-sm"
              ></span>
              Criar
            </button>
          </div>
        </div>
      </Form>
    </div>

</div>
  </template>


<script>
  import { Form, Field, ErrorMessage } from "vee-validate";
  import * as yup from "yup";

  export default {
    name: "locais-add",
    components: {
      Form,
      Field,
      ErrorMessage,
    },
    data() {
      const schema = yup.object().shape({


          Name: yup.string().required("Name is required!"),
          Cep: yup.string().required("Cep is required!"),
          Numero: yup.string().required("Numero is required!"),
          Rua: yup.string().required("Rua is required!"),
          Bairro: yup.string().required("Bairro is required!"),
          Cidade: yup.string().required("Cidade is required!"),
          Estado: yup.string().required("Estado is required!"),

      });

      return {
        loading: false,
        message: "",
        schema,
      };
    },

    methods: {
      handlelocais(content) {
        this.message = "";
      this.successful = false;
     // this.loading = true;

      this.$store.dispatch("locais/create", content).then(
        (data) => {
          this.message = data.message;
          this.successful = true;
          this.loading = false;
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.successful = false;
          this.loading = false;
        }
      );
      },
    },
  };
  </script>
  <style>
  .submit-form {
    max-width: 300px;
  }
  </style>