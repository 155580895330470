import Axios from '../services/Axios';
//import authHeader from './auth-header';

const API_URL = '/client/eventos';

class EventosDataService {
  getAll() { //used
    return Axios.get(API_URL + "/all_disponivel");
  }
  get(id) { //used
    return Axios.get(API_URL + `/${id}`);
  }

  create(data) { //used
    return Axios.post(API_URL + "/add", data);
  }



  getAllAtivo(data) {
    return Axios.get(API_URL + "/all_ativo", data);
  }
  update(id, data) {
    return Axios.put(API_URL + `/update/${id}`, data);
  }

  delete(id) {
    return Axios.delete(API_URL + `/delete/${id}`);
  }

  findByTitle(title) {
    return Axios.get(API_URL + `?title=${title}`);
  }

}

export default new EventosDataService();