<template>
  <Menu_profile />
  <div class="container container-content">
    <div class="title-cover">
      <h2 class="dance-title">Meus Ingressos</h2>
    </div>
    <div class="row cardblank">
      <div class="line_buttons">
        <a
          href="#"
          class="btn-normal"
          :class="currentpage === '/conta/ingressos' ? 'active' : ''"
          ><span>ATIVOS</span></a
        >
        <a
          href="#encerrados"
          class="btn-normal"
          :class="currentpage === '/conta/ingressos#encerrados' ? 'active' : ''"
          ><span>ENCERRADOS</span></a
        >
      <!--   <div class="contentd">
          <input
            type="text"
            name="pesquisar"
            placeholder="Pesquise festas, eventos ou um genêro musical"
            id="pesquisar"
          />
        </div> -->
      </div>
      <div class="divider"></div>

      <div  class="content-ingressos" v-if="currentpage === '/conta/ingressos'">
        <div
          v-for="(item, key) in ingressosAtivos"
          :key="key"

        >
          <div class="ingresso">
            <div class="imageclas">
              <img
                :src="item.img ? item.img : '../assets/img/noimage-4.jpg'"
                alt=""
              />
            </div>
            <div class="ingresso_conteudo">
              <ul class="primary_card">
                <li>
                  <i aria-hidden="true" class="fas fa-map-marker-alt"></i>
                  {{ " " + item.endereco.cidade + " - " + item.endereco.estado }}
                </li>
                <li>
                  <i aria-hidden="true" class="fas fa-calendar-alt"></i>
                  {{ " " + item.data_i }}{{ item.data_f ? " > " + item.data_f : "" }}
                </li>
                <li>
                  <i aria-hidden="true" class="far fa-clock"></i>{{ " " + item.hora_i }}
                  {{ " " + item.hora_f ? " > " + item.hora_f : "" }}
                </li>
                <a :href="item.mapa_link" target="_blank">VER MAPA</a>
              </ul>

              <div class="divider"></div>
              <div class="second_card">
                <button class="btn-link-a" @click="openIngresso(item)">IMPRIMIR INGRESSO</button>
                <button class="btn-link-a" @click="openPedidos(item)">VER PEDIDO</button>
                <button  class="btn-link-a" @click="openEditarUser(item)" :disabled="checkdate(item)"
                  >EDITAR PARTICIPANTE</button
                >
                <button class="btn-link-a" @click="openCancelarPedido(item)" :disabled="checkdate(item)"
                  >CANCELAR PEDIDO</button
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-else-if="currentpage === '/conta/ingressos#encerrados'">
        <div
          v-for="(item, key) in ingressosDesativados"
          :key="key"
          class="content-ingressos"
        >
          <div class="ingresso">
            <div class="imageclas">
              <img
                :src="item.img ? item.img : '../assets/img/noimage-4.jpg'"
                alt=""
              />
            </div>
            <div class="ingresso_conteudo">
              <ul class="primary_card">
                <li>
                  <i aria-hidden="true" class="fas fa-map-marker-alt"></i>
                  {{ item.endereco.cidade + " - " + item.endereco.estado }}
                </li>
                <li>
                  <i aria-hidden="true" class="fas fa-calendar-alt"></i
                  >{{ item.data_i }}{{ item.data_f ? " > " + item.data_f : "" }}
                </li>
                <li>
                  <i class="far fa-clock"></i>{{ item.hora_i }}
                  {{ item.hora_f ? " > " + item.hora_f : "" }}
                </li>
                <a :href="item.mapa_link" target="_blank">VER MAPA</a>
              </ul>

              <div class="divider"></div>
              <div class="second_card">
                <a href="#" @click="openIngresso(item)">IMPRIMIR INGRESSO</a>
                <a href="#" @click="openPedidos(item)">VER PEDIDO</a>
                <a href="#" @click="openEditarUser(item)"
                  >EDITAR PARTICIPANTE</a
                >
                <a href="#" @click="openCancelarPedido(item)"
                  >CANCELAR PEDIDO</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div
          v-for="(item, key) in allIngressos"
          :key="key"
          class="content-ingressos"
        >
          <div class="ingresso">
            <div class="imageclas">
              <img
                :src="item.img ? item.img : '../assets/img/noimage-4.jpg'"
                alt=""
              />
            </div>
            <div class="ingresso_conteudo">
              <ul class="primary_card">
                <li>
                  <i aria-hidden="true" class="fas fa-map-marker-alt"></i>
                  {{ item.endereco.cidade + " - " + item.endereco.estado }}
                </li>
                <li>
                  <i aria-hidden="true" class="fas fa-calendar-alt"></i
                  >{{ item.data_i }}{{ item.data_f ? " > " + item.data_f : "" }}
                </li>
                <li>
                  <i class="far fa-clock"></i>{{ item.hora_i }}
                  {{ item.hora_f ? " > " + item.hora_f : "" }}
                </li>
                <a :href="item.mapa_link" target="_blank">VER MAPA</a>
              </ul>

              <div class="divider"></div>
              <div class="second_card">
                <a href="#" @click="openIngresso(item)">IMPRIMIR INGRESSO</a>
                <a href="#" @click="openPedidos(item)">VER PEDIDO</a>
                <a href="#" @click="openEditarUser(item)"
                  >EDITAR PARTICIPANTE</a
                >
                <a href="#" @click="openCancelarPedido(item)"
                  >CANCELAR PEDIDO</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popup
        :open="popupTriggers.isopen"
        :type="1"
        :title="'Meu ingresso'"
        @close="closeIngresso()"
      >
      <div class="height-ingressos">
        <Ingresso :dados_final="ingressoOpened" id="imprimir"></Ingresso>

      </div>

        <div class="popup-footer">

            <button class="btn btn-pink cupombtn" @click="printDIV('imprimir')">
              IMPRIMIR
            </button></div>

      </Popup>
    </div>
  </div>
</template>

<style scoped>


.popup-footer {
  border-top: solid 1px rgb(25 31 40 / 8%);
    position: absolute;
    bottom: 15px;
    width: 100%;
    display: flex;
    padding: 18px;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
}
.popup-footer .resumo-esquerda h4 {
  margin: 10px;
}
.popup-footer .resumo-esquerda {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  align-content: flex-start;
}

.popup-footer .resumo-direita p {
  margin: 5px 10px;
}
.popup-footer .resumo-direita {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.height-ingressos {


  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  bottom: 120px;
}

.btn-link-a {
  color: #e50062;
  cursor: pointer;
}


.btn-link-a:hover {
  color: #ff0671;
  cursor: pointer;
}

.btn-link-a:disabled {
  color: #8d8d8d!important;
  cursor: auto;
}

.primary_card {
  color: #000;
}
.primary_card i {
  color: #d52b74;
}
.primary_card a:hover {
  margin-top: 32px !important;
}
.ticket-smile a:hover {
  color: #000;
}
.second_card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
}
.divider {
  border-top: 1px solid rgba(217, 217, 217, 0.5);
  width: 100%;
  padding-top: 20px;
}
.our-mission-info {
  color: #000 !important;
}

.our-mission-info h4 {
  color: #000;
  text-transform: uppercase;
}

.content-ingressos {
  display: flex;
  flex-wrap: wrap;
  /* margin: auto; */

  padding: 10px;
}
.ingresso {
  box-shadow: rgb(25 31 40 / 18%) 0px 6px 24px 0px;
  border-radius: 15px;
  margin: 5px;
  max-width: 250px;
}

.ingresso_conteudo {
  padding: 15px;
}
.imageclas img {
  width: 100%;
  border-radius: 15px;
  -o-object-fit: cover;
  object-fit: cover;
  height: 190px;
  max-height: 190px;
}

.imageclas {
  height: 200px;
}


.header-nav-conta {
  border-bottom: 1px #393939 solid;
}
.btn-normal {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 2px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;

  padding-left: 27px;
  padding-right: 27px;
  border-color: #d52b74;

  letter-spacing: 0.2em;
  font-weight: 500;
  -webkit-box-shadow: 0px 0px 20px rgb(27 49 168 / 10%);
  box-shadow: 0px 0px 20px rgb(27 49 168 / 10%);
  border-radius: 10px;

  background-color: #fff;
}

.contentd input {
  color: #000 !important;
}
.btn-normal:hover {
  color: #000 !important;
}
.btn-normal.active {
  background-color: #d52b74;
  color: #fff !important;
}

.line_buttons {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 20px;
}
.line_buttons a {
  text-decoration: none;
  margin-bottom: 10px;

  cursor: pointer;
}

.title-cover h2 {
  color: #fff !important;
}
.cardblank {
  background-color: #fff;
  padding: 20px;
  border-radius: 15px;
}
.cardblank h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000 !important;
}
.jumbotron h3 {
  color: #000;
}

.container-content {
  margin-bottom: 40px;
  margin-top: 40px;
}
</style>

<script>
import Menu_profile from "../../blocos/Menu_profile.vue";
import UserDataService from "../../../services/user.service";
import { ref } from "vue";
import Popup from "../../blocos/Popup.vue";
import Ingresso from "../../MinhaConta/User/printingresso.vue";

export default {
  components: {
    Popup,
    Ingresso,
    Menu_profile,
  },
  name: "Profile",
  setup() {
    const popupTriggers = ref({
      isopen: false,
      isopenCupom: false,
      isfinish: false,
      isPayment: false,
    });
    return {
      popupTriggers,
      ingressoOpened: {}
    };
  },

  data() {
    return {
      img: "",
      img2: "https://firebasestorage.googleapis.com/v0/b/ticket-api-a6731.appspot.com/o/eventos%2FImagem%20do%20WhatsApp%20de%202023-06-28%20%C3%A0(s)%2018.04.37.jpg?alt=media&token=52ad11b2-e091-450e-9ced-912ab6e64ab3",

      apiData: [],
      typeselect: false,
      ingressosAtivos: [
        {
          endereco: {
            rua: "",
            num: "",
            bairro: "",
            complemento: "",
            cidade: "1",
            estado: "",
            cep: "",
          },
          img: "",
          data_i: "",
          data_f: "",
          hora_i: "",
          hora_f: "",
          mapa_link: "",
          nome_user: "",
          documento: "",
          valor: "",
          numero_do_ingresso: "",
          qrcode: "",
        },
      ],
      ingressosDesativados: [
        {
          endereco: {
            rua: "",
            num: "",
            bairro: "",
            complemento: "",
            cidade: "2",
            estado: "",
            cep: "",
          },
          img: "",
          data_i: "",
          data_f: "",
          hora_i: "",
          hora_f: "",
          mapa_link: "",
          nome_user: "",
          documento: "",
          valor: "",
          numero_do_ingresso: "",
          qrcode: "",
        },
      ],
      allIngressos: [
        {
          endereco: {
            rua: "",
            num: "",
            bairro: "",
            complemento: "",
            cidade: "3",
            estado: "",
            cep: "",
          },
          img: "",
          data_i: "",
          data_f: "",
          hora_i: "",
          hora_f: "",
          mapa_link: "",
          nome_user: "",
          documento: "",
          valor: "",
          numero_do_ingresso: "",
          qrcode: "",
        },
      ],
      activepage: 1,
      endereco: {},
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user.user;
    },
    currentpage() {
      return this.$route.fullPath;
    },
  },
  methods: {
    checkdate(item){
      var dataAtual = new Date();
      console.log(item)

var dataString = item.data_i;
var partesData = dataString.split('/');
var dataparse = partesData[2] + '-' + partesData[1]+ '-' + partesData[0]+ 'T' + item.hora_i + ':00'
var dataComparacao = new Date(dataparse);

console.log(dataComparacao)

dataComparacao.setDate(dataComparacao.getDate() - item.data_cancel_change ? item.data_cancel_change : 2);

if (dataAtual >= dataComparacao ) {
  return true
}

      return false
    },
    closeIngresso() {
      this.popupTriggers.isopen = !this.popupTriggers.isopen;
      const el = document.body;
      this.ingressoOpened = {}

      el.classList.remove("noscroll");
    },
     printDIV(i){
   var cssEstilos = '<link rel="stylesheet" href="/assets/css/ingresso.css">';
   var imp = window.open('', 'div', 'width='+window.innerWidth+',height='+window.innerWidth);

   var cSs = document.querySelectorAll("link[rel='stylesheet']");
   for(var x=0;x<cSs.length;x++){
      cssEstilos += '<link rel="stylesheet" href="'+cSs[x].href+'">';
   }

   imp.document.write('<html><head><title>' + document.title  + '</title>');
   imp.document.write(cssEstilos+'</head><body>');
   imp.document.write(document.getElementById(i).innerHTML);
   imp.document.write('</body></html>');

   setTimeout(function(){
      imp.print();
      imp.close();
   },1500);
},
    openIngresso(item) {

      this.ingressoOpened = item
      this.popupTriggers.isopen = true;



      const el = document.body;
      el.classList.add("noscroll");
    },
  },
  mounted() {
    this.activepage = this.$route.path;

    UserDataService.getMyingressos(this.id_page).then(
      (response) => {
        this.apiData = response.data;
        this.allIngressos = response.data.allIngressos;
        this.ingressosDesativados = response.data.ingressosDesativados;
        this.ingressosAtivos = response.data.ingressosAtivos;
      },

      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );

    if (!this.currentUser) {
      this.$router.push("/login");
    }
  },
};
</script>
