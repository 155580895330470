<template>
  <div class="centeredticket">
    <main class="ticket-system">
      <div class="receipts-wrapper">
        <div class="receipts">
          <div class="imageclas2">
            <img src="../../../assets/images/logo1.png" alt="logo" />
          </div>
          <div class="receipt">
            <div class="imageclas">
              <img :src="dados_final.img ? dados_final.img : '../assets/img/noimage-4.jpg'" alt="" />
            </div>
            <h3>{{ dados_final.titulo }}</h3>

            <div class="details">
              <div class="item">
                <span>Nome</span>
                <h5>{{ dados_final.nome_user }}</h5>
              </div>
              <div class="item">
                <span>Documento</span>
                <h6>CPF {{ dados_final.documento }}</h6>
              </div>

              <div class="item">
                <span>Valor</span>
                <h6>R$ {{ dados_final.valor  }}</h6>
              </div>
              <div class="item">
                <span>ID do ingresso</span>
                <h6>{{ dados_final.numero_do_ingresso }}</h6>
              </div>
              <div class="item">
                <span>Data do evento</span>
                <h4>{{ dados_final.data_i }} - {{ dados_final.hora_i }}</h4>
              </div>
            </div>
          </div>

          <div class="receipt qr-code">
            <qrcode-vue
              :value="dados_final.qrcode"
              render-as="svg"
              :options="qrOptions"
            ></qrcode-vue>
            <div class="description">
              <h6>{{ dados_final.titulo }} - {{ dados_final.data_i }}</h6>
              <p>R$ {{ dados_final.valor   }}</p>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
  <!-- INSPO:  https://www.behance.net/gallery/69583099/Mobile-Flights-App-Concept -->

</template>

<script>
import QrcodeVue from "qrcode.vue";

export default {
  components: {
    QrcodeVue,
  },
  props: {
    dados_final: {
      required: true,
    },
    stepa: {
      required: true,
    },
  },
  data() {
    return {
      img: "",
      qrOptions: {
        typeNumber: 0,
        size: 300,

        mode: "Byte",
        errorCorrectionLevel: "L",
        margin: 4,
        scale: 4,
        color: {
          dark: "#000000",
          light: "#ffffff",
        },
      },
      dados: {
        nome_festa: "SMILE FEST",
        qrData: "https://www.example.com",
        nome: "Nickolas",
        documento: "456.456.456-45",
        data_evento: "01/07/2023",
        hora_evento: "21:00",
        valor: "22.00",
        numero_ingresso: "1110934023/ICB",
        nome_ingresso: "Ingresso1",
      },
      img2: "https://firebasestorage.googleapis.com/v0/b/ticket-api-a6731.appspot.com/o/eventos%2FImagem%20do%20WhatsApp%20de%202023-06-28%20%C3%A0(s)%2018.04.37.jpg?alt=media&token=52ad11b2-e091-450e-9ced-912ab6e64ab3",
    };
  },
};
</script>

<style lang="scss" scoped>

.centeredticket{
  margin-top:20px;
  display: flex;
    justify-content: center;
    height: 100%;
    overflow-y: scroll;
}
* {
  box-sizing: border-box;
}
html,
body {
  height: 100%;
  margin: 0;
}
body {
  @import url("https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700");
  font-family: "Ubuntu", sans-serif;
  background-color: #fff !important;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #1c1c1c;
  display: flex;
  justify-content: center;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000;
}

.qr-code svg {
  width: 240px !important;
  height: 240px !important;
}


.ticket-system {
  max-width: 385px;
  .top {
    display: flex;
    align-items: center;
    flex-direction: column;
    .title {
      font-weight: normal;
      font-size: 1.6em;
      text-align: left;
      margin-left: 20px;
      margin-bottom: 50px;
      color: #000;
    }
    .printer {
      width: 90%;
      height: 20px;
      border: 5px solid #000;
      border-radius: 10px;
      box-shadow: 1px 3px 3px 0px rgba(0, 0, 0, 0.2);
    }
  }

  .receipts-wrapper {
    overflow: hidden;
    margin-top: -10px;
    padding-bottom: 10px;
  }

  .receipts {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    transform: translateY(-510px);

    animation-duration: 2.5s;
    animation-delay: 500ms;
    animation-name: print;
    animation-fill-mode: forwards;

    .receipt {
      padding: 25px 30px;
      text-align: left;
      min-height: 200px;
      width: 88%;
      background-color: #fff;
      border-radius: 10px 10px 20px 20px;
      box-shadow: 1px 3px 8px 3px rgba(0, 0, 0, 0.2);

      .airliner-logo {
        max-width: 80px;
      }

      .route {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 30px 0;

        h2 {
          font-weight: 300;
          font-size: 2.2em;
          margin: 0;
        }
      }

      .details {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .item {
          display: flex;
          flex-direction: column;
          min-width: 70px;

          span {
            font-size: 0.8em;
            color: rgba(28, 28, 28, 0.7);
            font-weight: 500;
          }
          h3 {
            margin-top: 10px;
            margin-bottom: 25px;
          }
        }
      }

      &.qr-code {
        height: 190px;
        min-height: unset;
        position: relative;
        border-radius: 20px 20px 10px 10px;
        display: flex;
        align-items: center;

        &::before {
          content: "";
          background: linear-gradient(to right, #fff 50%, #000 50%);
          background-size: 22px 4px, 100% 4px;
          height: 4px;
          width: 90%;
          display: block;
          left: 0;
          right: 0;
          top: -1px;
          position: absolute;
          margin: auto;
        }

        .qr {
          width: 140px;
          height: 140px;
        }

        .description {
          margin-left: 20px;

          h2 {
            margin: 0 0 5px 0;
            font-weight: 500;
          }
          p {
            margin: 0;
            font-weight: 400;
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {

  .qr-code svg {
  max-width: 160px !important;
  max-height: 160px !important;
}
}

@keyframes print {
  0% {
    transform: translateY(-510px);
  }
  35% {
    transform: translateY(-395px);
  }
  70% {
    transform: translateY(-140px);
  }
  100% {
    transform: translateY(0);
  }
}

.imageclas img {
  width: 100%;
  border-radius: 15px;
  -o-object-fit: cover;
  object-fit: cover;
  height: 190px;
  max-height: 190px;
}

.imageclas {
  height: 200px;
}

.imageclas2 img {
  border-radius: 15px;
  -o-object-fit: cover;
  object-fit: cover;
  height: 80px;
  filter: contrast(0) brightness(0);
  max-height: 100px;
}

.imageclas2 {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
</style>
