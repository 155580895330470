import Axios from "../services/Axios";

import authHeader from "./auth-header";

const API_URL = "/client/users";

class UserService {



  getPublicContent() {
    return Axios.get(API_URL + "all");
  }

  getTermos() { //used
    return Axios.get(API_URL + "/client/staticpages");
  }

  getMyingressos() { //used
    return Axios.get(API_URL + "/my_ingressos", { headers: authHeader() });
  }


  getUserBoard() {
    return Axios.get(API_URL + "user", { headers: authHeader() });
  }

  getModeratorBoard() {
    return Axios.get(API_URL + "mod", { headers: authHeader() });
  }

  getAdminBoard() {
    return Axios.get(API_URL + "admin", { headers: authHeader() });
  }

  updateUserInfo(data) {
    return Axios.put(API_URL + "/meusdados", data, { headers: authHeader() });
  }
}

export default new UserService();
