import axios from 'axios';
//import authHeader from './auth-header';

const API_URL = 'http://192.168.0.65:8080/api/categorias';

class CategoriasService {
  getAll() {
    return axios.get(API_URL + "/all");
  }

  get(id) {
    return axios.get(API_URL + `/${id}`);
  }
  getAllAtivo(data) {
    return axios.get(API_URL + "/all_ativo", data);
  }
  add(data) {
    return axios.post(API_URL + "/add", data);
  }

  update(id, data) {
    return axios.put(API_URL + `/update/${id}`, data);
  }

  delete(id) {
    return axios.delete(API_URL + `/delete/${id}`);
  }

  findByTitle(title) {
    return axios.get(API_URL + `?title=${title}`);
  }
}

export default new CategoriasService();