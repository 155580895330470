<template>
   <div class="checkout-container">
    <h1>Termos de uso e Políticas de Privacidade</h1>

   <!--  <button @click="adicionarCampos">Adicionar campos</button>
    <div v-for="(campo, index) in campos" :key="index">
      <input v-model="campo.titulo" type="text" :placeholder="'Título ' + (index + 1)">
      <input v-model="campo.valor" type="number" :placeholder="'Valor ' + (index + 1)">
      <input v-model="campo.quantidade" type="number" :placeholder="'Quantidade ' + (index + 1)">
      <input v-model="campo.dataFinal" type="date" :placeholder="'Data Final ' + (index + 1)">
      <input v-model="campo.tipo" type="number" :placeholder="'Tipo ' + (index + 1)">
      <template v-if="campo.tipo === 4">
        <button @click="adicionarLotes(index)">Adicionar campos especiais</button>
        <div v-for="(Lotes, idx) in campo.camposLotes" :key="idx">
          <input v-model="Lotes.titulo" type="text" :placeholder="'Título ' + (idx + 1)">
      <input v-model="Lotes.valor" type="number" :placeholder="'Valor ' + (idx + 1)">
      <input v-model="Lotes.quantidade" type="number" :placeholder="'Quantidade ' + (idx + 1)">
      <input v-model="Lotes.dataFinal" type="date" :placeholder="'Data Final ' + (idx + 1)">
      <input v-model="Lotes.tipo" type="number" :placeholder="'Tipo ' + (idx + 1)">
     </div>
      </template>
    </div>
    <button @click="gerarArray">Gerar array</button>
    <pre>{{ arrayCampos }}</pre> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      campos: [],
      arrayCampos: []
    };
  },
  methods: {
    adicionarCampos() {
      this.campos.push({
        titulo: '',
        valor: '',
        quantidade: '',
        dataFinal: '',
        tipo: '',
        camposLotes: []
      });
    },
    adicionarLotes(index) {
      this.campos[index].camposLotes.push({ valor: '' });
    },
    gerarArray() {
      this.arrayCampos = [...this.campos];
    }
  },
  watch: {
    'campos': {
      deep: true,
      handler() {
        this.campos.forEach(campo => {
          if (campo.tipo !== 4) {
            campo.camposLotes = [];
          }
        });
      }
    }
  }
};
</script>

<style>
.checkout-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  color: #000;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.checkout-container h1 {
  color: #000;
}
.checkout-container h2 {
  color: #000;
}
.checkout-container  label {
  font-weight: bold;
}

.checkout-container  input[type="text"],
input[type="number"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
}



.checkout-container pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.checkout-container .transaction-completed {
  margin-top: 20px;
  padding: 10px;
  background-color: #dff0d8;
  border: 1px solid #d6e9c6;
  border-radius: 4px;
}
</style>
