<template>
  <div>
    <div class="container">
      <section class="s-dance-counter">
        <div class="container">
          <img
            class="dance-counter-effect dance-counter-effect-1"
            src="../assets/img/dance-counter-effect-1.svg"
            alt="img"
          />
          <img
            class="dance-counter-effect dance-counter-effect-2"
            src="../assets/img/dance-counter-effect-2.svg"
            alt="img"
          />
          <img
            class="dance-counter-effect dance-counter-effect-3"
            src="../assets/img/dance-counter-effect-3.svg"
            alt="img"
          />
          <h2>Encontre os melhores eventos</h2>
          <div class="contentd">
            <input
              type="text"
              name="pesquisar"
              placeholder="Pesquise festas, eventos ou um genêro musical"
              id="pesquisar"
            />
          </div>
        </div>
      </section>

    </div>
    <section
      class="s-choose-us"
      style="background-image: url(assets/img/bg-1.svg)"
    >
      <div class="container">
        <h2 class="title"><span>Explorar</span></h2>

        <div class="row">
          <div class="eventos">
            <div  class="eventos_items"   v-for="(item, key) in eventos" :key="key" >
              <a :href="'/evento/' + item.id" >
              <div class="image" >
                <img :src='item.image ? item.image : "../assets/images/noimage.png"' />
              </div>
              <div class="content2">
							<span class="prof">{{item.categoriaId}}</span>
                <span class="ticket-smile-member-info">
							<h4 class="name"> {{item.name}}</h4>
						</span>
                <ul class="mission-meta aa" >
              <li>
                <i aria-hidden="true" class="fas fa-map-marker-alt"></i>                {{ item.locai.cidade + " - " + item.locai.estado }}

              </li>
              <li>
                <i aria-hidden="true" class="fas fa-calendar-alt"></i>{{item.data_i}} > {{item.data_f}}
              </li>
              <li><i class="far fa-clock"></i>{{item.hora_i}} > {{item.hora_f}}</li>
            </ul>


              </div>
               </a>
            </div>

          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<style scoped>



.slide a{
  text-decoration: none;
}
.slide{
  cursor: pointer;
}
.s-dance-counter {
  padding: 32px 0 28px;
}
.prof{
  color: #bcbcbc;
}
.imagem-slide {
  border-radius: 8px;
width: 100%;
}
  .buttondiv{
    margin-bottom: 20px;
  }
  .mission-meta.aa li {
  margin: 0px;
}


  .mission-meta{
    margin: 10px 0 0 0 ;
    -webkit-box-pack: center!important;
    -ms-flex-pack: center!important;
    justify-content: center!important;
  }
  .ticket-smile a{
    color: #d52b74;
  }
.our-mission-info {
  color: #000 !important;
  padding: 15px!important;

}

.our-mission-info h4 {
  color: #000;
  text-transform: uppercase;
}

/* NOVOOOO */
.box_footer {
  display: flex;
  /* border: red 2px solid; */
  margin-top: 15px;
  height: 100%;
  align-items: flex-end;
}
.eventos-back {
  background-color: #ebebeb;
  /* width: 100vw; */
  /* position: relative; */
  /* left: 50%; */
  /* border: red 2px solid; */
  /* right: 50%; */
  /* margin-left: -50vw; */
  /* margin-right: -50vw; */
}

.image-slide{
  width: 50%;

}

.eventos {
  display: flex;
  /* border: red 2px solid; */
  padding: 10px;
  margin-bottom: 30px;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  width: 90vw;
  margin: 0 auto;
  /* border: red 2px solid; */
}
.eventos_title {
  display: flex;
  /* border: red 2px solid; */
  padding: 10px;
  margin-bottom: 30px;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  width: 80vw;
  margin: 0 auto;
  /* border: red 2px solid; */
}
.botao_slide {
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  border-color: rgb(0, 151, 255);
  color: rgb(0, 151, 255);
  background-color: transparent;
  padding: 16px 24px;
  font-size: 14px;
  text-transform: uppercase;
}
.title {
  /* border: red 2px solid; */
  color: #000;
}
.eventos h1 {
  display: block;
}
.eventos_items {
  background: #fff!important;
  border-radius: 8px;
  width: 250px;
  /* border: red 2px solid; */
  box-shadow: rgb(25 31 40 / 8%) 0px 6px 24px 0px;
  margin: 10px 10px 50px 10px;
  cursor: pointer;

}
.eventos .eventos_items:hover {
  box-shadow: rgb(25 31 40 / 20%) 0px 6px 24px 0px;
  background: #fff;
  border-radius: 8px;
  width: 250px;
  /* border: red 2px solid; */
}

.eventos .eventos_items img {
  border-radius: 8px;
  width: 100%;
  max-width: 250px;

  max-height: 300px;
}

@media (max-width: 992px) {
  .eventos .eventos_items {

  width: 300px;


}

.eventos {
  display: flex;
  /* border: red 2px solid; */
  padding: 10px;
  margin-bottom: 30px;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 90vw;
  margin: 0 auto;
  /* border: red 2px solid; */
}
.eventos .eventos_items:hover {

  width: 300px;
  /* border: red 2px solid; */



}
.eventos .eventos_items img {
  border-radius: 8px;
  width: 100%;
  max-width: 300px;

  max-height: 300px;
}
}
.eventos .eventos_items .content2 {
  padding: 10px;
  font-weight: 600;
    text-transform: uppercase;
    font-size: 16px;
  text-align: left;color: #d52b74;
  height: 100%;
  /* border: red 2px solid; */
}
.eventos .eventos_items .content2 h4{

  color: #000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;

  text-transform: uppercase;
    font-weight: 600;
    font-size: 25px;
    margin-bottom: 6px;
  /* border: red 2px solid; */
}

.space {
  padding: 20px;
  /* border: red 2px solid; */
}
.card-pesquisar {
  /* border: red 2px solid; */
  font-size: 24px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 20px;
  width: 100%;
  height: 200px;
  /*  background-image: url("~@/assets/wave.svg");
  background-repeat: no-repeat;
  background-size: 100%; */
  /* Center child horizontally*/
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 10px;
}
.contentd input {
  text-align: center;
  border-color: #393939;
  border-radius: 8px;
}
.card-pesquisar svg {
  border-radius: 8px;
}
.card-pesquisar input {
  text-align: center;
  color: #fff;
  border-color: #fff;
  font-size: 20px;
  max-width: 490px;
  width: 100%;
  height: 42px;
  border-radius: 8px;
  top: 100px;
  display: flex;
}

.slide {
  /* border: red 2px solid; */
  box-shadow: rgb(25 31 40 / 15%) 0px 6px 24px 0px;
  color: #fff!important;

  border-radius: 8px;
}
.slide-item {
border-radius: 14px;
  background-color: #fff;
  margin: 10px;
}

.slide .slide-item {
  display: flex;
    align-items: flex-start;
    flex-direction: row;    align-items: center;
}
.slide .slide-item .image {
  /* border: red 2px solid; */

  width:  100%;
  border-radius: 8px;

}.mission-info-text{
  display: none;
}
.sse{
  background-color: rgb(255, 255, 255);  border-radius: 8px;

}
@media (min-width: 992px) {
  .sse{
  background-color: rgb(255, 255, 255);  border-radius: 8px;
  max-height: 350px;
}
  .slided{
  width: 50%;
}
.mission-info-text{
  display: block;
}
}
.slide .slide-item .image {

 width: 50%;
}
.slide .slide-item .content {
  width: 50%;


}


@media (max-width: 992px) {
  .slide .slide-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .slide .slide-item .content {
    width: 100%;
  }
}


#slider input[type=radio] {
   display: none;
}
#slider label {
   cursor:pointer;
   text-decoration: none;
}
#slides {


}
#overflow {
   width: 100%;
   overflow: hidden;
}
#slide0:checked ~ #slides .inner {
   margin-left: 0;
}
#slide1:checked ~ #slides .inner {
   margin-left: 0;
}
#slide2:checked ~ #slides .inner {
   margin-left: -100%;
}
#slide3:checked ~ #slides .inner {
   margin-left: -200%;
}
#slide4:checked ~ #slides .inner {
   margin-left: -300%;
}
#slides .inner {
   transition: margin-left 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
   width: 400%;


}
#slides .slide {
   width: 25%;
   float:left;
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100%;
   color: #fff;
}

#controls {
   margin: -180px 0 0 0;
   width: 100%;
   height: 50px;
   z-index: 3;
   position: relative;
}
#controls label {
   transition: opacity 0.2s ease-out;
   display: none;
   width: 50px;
   height: 50px;
   opacity: .4;
}
#controls label:hover {
   opacity: 1;
}
#slide1:checked ~ #controls label:nth-child(2),
#slide2:checked ~ #controls label:nth-child(3),
#slide3:checked ~ #controls label:nth-child(4),
#slide4:checked ~ #controls label:nth-child(1) {
   background: url(https://image.flaticon.com/icons/svg/130/130884.svg) no-repeat;
   float:right;
   margin: 0 -50px 0 0;
   display: block;
}
#slide1:checked ~ #controls label:nth-last-child(2),
#slide2:checked ~ #controls label:nth-last-child(3),
#slide3:checked ~ #controls label:nth-last-child(4),
#slide4:checked ~ #controls label:nth-last-child(1) {
   background: url(https://image.flaticon.com/icons/svg/130/130882.svg) no-repeat;
   float:left;
   margin: 0 0 0 -50px;
   display: block;
}

#bullets {
   margin: 150px 0 0;
   text-align: center;
}
#bullets label {
   display: inline-block;
   width: 10px;
   height: 10px;
   border-radius:100%;
   background: #ccc;
   margin: 0 10px;
}
#slide0:checked ~ #bullets label:nth-child(0),
#slide1:checked ~ #bullets label:nth-child(1),
#slide2:checked ~ #bullets label:nth-child(2),
#slide3:checked ~ #bullets label:nth-child(3),
#slide4:checked ~ #bullets label:nth-child(4) {
   background: #444;
}
@media screen and (max-width: 900px) {
   #slide1:checked ~ #controls label:nth-child(2),
   #slide0:checked ~ #controls label:nth-child(0),
   #slide2:checked ~ #controls label:nth-child(3),
   #slide3:checked ~ #controls label:nth-child(4),
   #slide4:checked ~ #controls label:nth-child(1),
   #slide1:checked ~ #controls label:nth-last-child(2),
   #slide0:checked ~ #controls label:nth-last-child(0),
   #slide2:checked ~ #controls label:nth-last-child(3),
   #slide3:checked ~ #controls label:nth-last-child(4),
   #slide4:checked ~ #controls label:nth-last-child(1) {
      margin: 0;
   }
   #slides {

      margin: 0 auto;
   }
}
.eventos_items a{
  text-decoration: none;
}
</style>
<script>
import UserService from "../services/user.service";

export default {
  name: "Explorar",

  data() {
    return {
      content: "",
      eventos: "",
      eventos_destaque: "",
      categorias: "",
    };
  },
  mounted() {
    UserService.getPublicContent().then(
      (response) => {
        this.content = response.data;
        this.eventos = this.content.eventos

        console.log(this.eventos  )
        this.categorias = this.content.categorias
        this.eventos_destaque = this.content.eventos_destaque
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
};
</script>
