<template>
  <div class="container container-content">
    <div class="title-cover">
      <h2 class="dance-title">Registre-se</h2>
    </div>
    </div>


  <div class="col-md-12">

    <div class="card card-container">
      <div class="titletop">
        <h5>Seja bem vindo</h5>
        <p>Faça o seu registro para ter acesso a todas as nossas funcionalidades!</p>
      </div>
      <img
        id="profile-img"
        src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
        class="profile-img-card"
      />
      <Form @submit="handleRegister" :validation-schema="schema">
        <div >
          <div class="form-group">
            <label for="nome">Nome</label>
            <Field name="nome" type="text" class="form-control" />
            <ErrorMessage name="nome" class="error-feedback" />
          </div>
          <div class="form-group">
            <label for="telefone">telefone</label>
            <Field name="telefone" type="text" class="form-control" v-maska  data-maska="['+55 (##) # ####-####']" />


            <ErrorMessage name="telefone" class="error-feedback" />
          </div>

          <div class="form-group">
            <label for="email">Email</label>
            <Field name="email" type="email" class="form-control" />
            <ErrorMessage name="email" class="error-feedback" />
          </div>
          <div class="form-group">
            <label for="password">Senha</label>
            <Field name="password" type="password" class="form-control" />
            <ErrorMessage name="password" class="error-feedback" />
          </div>
          <div class="form-group">
            <label for="retypePassword">Confirme sua Senha</label>
            <Field
              name="retypePassword"
              type="password"
              class="form-control"
            />
            <ErrorMessage name="retypePassword" class="error-feedback" />
          </div>
          <div class="form-group">
            <button class="btn btn-primary btn-block" :disabled="loading">
              <span
                v-show="loading"
                class="spinner-border spinner-border-sm"
              ></span>
              Registrar
            </button>
          </div>
        </div>


      </Form>


      <div
        v-if="message"
        class="alert"
        :class="successful ? 'alert-success' : 'alert-danger'"
      >
        {{ message }}
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import "yup-phone";
import { vMaska } from "maska"

export default {
  name: "Register",
  directives: { maska: vMaska },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      nome: yup
        .string()
        .required("O campo nome é obrigatório!")
        .min(3, "Deve ter no mínimo 3 caracteres!")
        .max(40, "Deve ter no máximo 20 caracteres!"),
      email: yup
        .string()
        .required("O campo email é obrigatório!")
        .email("Email inválido!")
        .max(50, "Deve ter no máximo 50 caracteres!"),
      password: yup
        .string()
        .required("O campo senha é obrigatório!")
        .min(6, "Deve ter no mínimo 6 caracteres!")
        .max(40, "Deve ter no máximo 40 caracteres!"),
      retypePassword: yup
        .string()
        .required("Por favor digite novamente a sua senha")
        .min(6, "Deve ter no mínimo 6 caracteres!")
        .oneOf([yup.ref("password")], "Suas senhas não coincidem."),
      telefone: yup
        .string()
        .required("O campo telefone é obrigatório!")
        .min(6, "Deve ter no mínimo 6 caracteres!")
        .phone("BR", true, "Deve ser um numero valido")
        .max(40, "Deve ter no máximo 40 caracteres!"),
    });

    return {
      successful: true,
      loading: false,
      message: "",
      schema,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.push("/conta");
    }
  },
  methods: {
    handleRegister(user) {
      this.message = "";
      this.successful = false;
      this.loading = true;
      console.log(user)
      this.$store.dispatch("auth/register", user).then(
        (data) => {
          this.message = data.message;
          this.successful = true;
          this.$router.push("/login?ms=1")

          this.loading = false;

        },
        (error) => {
          console.log(error.error)
          this.message = error.error
          this.successful = false;
          this.loading = false;


        }
      );
    },
  },
};
</script>

<style scoped>

input{
  color: #000!important;
}
@media only screen and (max-width: 600px) {
  form {
    width: 90vw!important;
    padding: 14px!important;

    min-width: initial;
  }
}
label {
  display: block;
  margin-top: 10px;
}
.cardblank {
  background-color: #fff;
  padding: 20px 0px;
  border-radius: 15px;
}
.title-cover h2 {
  color: #fff !important;
}
.cardblank h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000 !important;
}
.card-container.card {
  max-width: 650px !important;
  border-radius: 15px;
  padding: 40px 40px;
}
.alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    margin: 10px;
}
.card {
  background-color: #fff;
  color: #000;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.error-feedback {
  color: red;
}
</style>
