
<template>
  <div class="container">
    <div class="topo_link">

      <div class="topo_content">
        <div class="circle">
          <img src="../assets/images/noimage.png" />
        </div>

        <p class="nameTitle">NOME DO ORGANIZADOR</p>
        <p class="subTitle">NOME DO ORGANIZADOR</p>
        <div class="links">
          <div class="links_link">
            <i aria-hidden="true" class="fab fa-facebook-f"></i>
          </div>
          <div class="links_link">
            <i aria-hidden="true" class="fab fa-instagram"></i>
          </div>
          <div class="links_link">
            <i aria-hidden="true" class="fab fa-twitter"></i>
          </div>
          <div class="links_link">
            <i aria-hidden="true" class="fas fa-globe"></i>
          </div>
        </div>
      </div>
      <div class="moreinfo">
          <div class="tt">
            <button class="morebutton">
             <i class="fa fa-bars"></i>
            </button>
          </div>
      </div>
    </div>

    <div class="container top_content_link">
      <div class="content_links">
        <div class="link_item row">
          <div class="link_item_img">
            <div class="circle1">
              <img src="../assets/images/noimage.png" />
            </div>
          </div>
          <div class="link_item_title">
            <p>Link teste</p>
          </div>
          <div class="link_item_button">
            <div class="tt">
              <button class="sharebutton">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 448 512"
                >
                  <path
                    d="M246.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 109.3V320c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128zM64 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64c0 53 43 96 96 96H352c53 0 96-43 96-96V352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V352z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="footera">
      <img src="../assets/images/logo1.png" />
    </div>
    <p class="link-cookies">> Definições de cookies</p>
  </div>
</template>
<script>
/*
import EventosService from "../services/eventosData.service";
 */
export default {
  name: "Home",
  data() {
    return {
      content: "",
    };
  },
  mounted() {
    /* EventosService.getAll().then(
      (response) => {
        this.content = response.data;
        this.eventos = response.data.eventos;

        console.log(this.eventos);
        this.categorias = this.content.categorias;
        this.eventos_destaque = this.content.eventos_destaque;
      },
      (error) => {

        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        this.errorcontent = error;

      }
    );
 */

    document.getElementById("header_site").style.display = "none";
    document.getElementById("footer_site").style.display = "none";

    this.content = {
      nome: "nome",
      owner_id: "nome",
      imagem: "nome",
      status: 1,
      links: [
        {
          titulo: "1",
          url: "",
        },
        {
          titulo: "2",
          url: "",
        },
      ],
      eventos: [
        // verificar em eventos onde marcado no links rapidos
        { titulo: "", url: "" },
      ],
      produtos: [
        // verificar em produtos onde marcado no links rapidos
        { titulo: "", url: "" },
      ],
      planos: [
        // verificar em planos onde marcado no links rapidos
        { titulo: "", url: "" },
      ],
      social: {
        instagram: "",
        facebook: "",
        tiktok: "",
        twitter: "",
      },
      contatos: {
        email: "",
        telefone1: "",
        telefone2: "",
      },
    };
  },
  methods: {
    getYear() {
      const xmas = new Date();
      const year = xmas.getFullYear();

      if (year != "2022") {
        return "2022 - " + year;
      } else {
        return year;
      }
    },
  },
};
</script>

<style scoped>
.moreinfo{
  position: absolute;
    top: 20px;
    right: 20px;
}
.link-cookies {
  color: #d52b74;
  cursor: pointer;
}
.link-cookies:hover {
  color: #d52b7568;
}
.sharebutton {
  background-color: #aaa;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  overflow: hidden;
  position: relative;
}

.sharebutton svg {
  fill: #000;
}

.sharebutton:hover {
  background-color: #5e5e5e;
}

.sharebutton:hover svg {
  fill: #ffffff;
}




header {
  background: none;
  margin-top: 30px;
}

.morebutton {
  background-color: #aaa;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  overflow: hidden;
  position: relative;
}

.morebutton i {
  color: #000;
}

.morebutton:hover {
  background-color: #5e5e5e;
}

.morebutton:hover svg {
  fill: #ffffff;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 50vh;
}

main {
  flex: 1;
}
.footera {
  bottom: 0;
  width: 100%;
  height: 60px;
  display: flex;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: column;
}
.footera p {
  font-weight: 600;
}
.footera img {
  height: 60px;
}
.content_links a {
  text-decoration: none;
}
.content_links {
  margin: 10px 0px;
}

.link_item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #fff;
  padding: 10px;
  border-radius: 22px;
  color: #fff;
  cursor: pointer;
}

.link_item_title {
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0px 10px;
  width: auto;
}

.link_item_img {
  width: auto;
}

.link_item_title p {
  word-break: break-all;
}

.link_item_button {
  display: flex;
  justify-content: flex-end;
  min-width: 45px;
}
.link_item_button .tt {
  display: none;
}

.link_item:hover .link_item_button .tt {
  display: block;
}

.link_item:hover {
  background-color: #fff;
  color: #000;
}

footer {
  margin: 20px !important;
  background: none;
}

.dark-footer2 {
  background-color: #160e22;
}
.top_content_link {
  margin-top: 20px;
}
.topo_content {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.imagecontainer {
  width: 200px;
  height: 200px;
}
.imagecontainer img {
  margin: auto;
  border-radius: 50%;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.circle {
  background-color: #aaa;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: relative;
}

.circle img {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.circle1 {
  background-color: #aaa;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  overflow: hidden;
  position: relative;
}

.circle1 img {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: initial;
}
.nameTitle {
  font-size: 20px;
  font-weight: 700;

  margin-top: 10px;
  margin-bottom: 0px;
}
.subTitle {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 10px;
}
.topo_link {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  padding-top: 30px;
  justify-content: center;
  align-items: center;
}
.imagem-topo {
  margin-bottom: 5px;
}

.links {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.links_link {
  min-width: 40px;
  min-height: 40px;
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  border: 1px solid #8b8b8b4f;
  background-color: #000;
  padding: 8px 10px;
  margin: 5px;
}

.links_link:hover {
  background-color: #636363;
  cursor: pointer;
}

.links_link:hover i {
  color: #000;
}

/* A CIMA DA PAGE */
.eventos_items a {
  text-decoration: none;
}
.slide a {
  text-decoration: none;
}
.slide {
  cursor: pointer;
}
.s-dance-counter {
  padding: 32px 0 28px;
}
.prof {
  color: #bcbcbc;
}
.imagem-slide {
  border-radius: 8px;
  width: 100%;
}
.buttondiv {
  margin-bottom: 20px;
}

.mission-meta.aa li {
  margin: 0px;
}

.mission-meta {
  margin: 10px 0 0 0;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}
.ticket-smile a {
  color: #d52b74;
}
.our-mission-info {
  color: #000 !important;
  padding: 15px !important;
}

.our-mission-info h4 {
  color: #000;
  text-transform: uppercase;
}

/* NOVOOOO */
.box_footer {
  display: flex;
  /* border: red 2px solid; */
  margin-top: 15px;
  height: 100%;
  align-items: flex-end;
}
.eventos-back {
  background-color: #ebebeb;
  /* width: 100vw; */
  /* position: relative; */
  /* left: 50%; */
  /* border: red 2px solid; */
  /* right: 50%; */
  /* margin-left: -50vw; */
  /* margin-right: -50vw; */
}

.image-slide {
  width: 50%;
}

.eventos {
  display: flex;
  /* border: red 2px solid; */
  padding: 10px;
  margin-bottom: 30px;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  width: 90vw;
  margin: 0 auto;
  /* border: red 2px solid; */
}
.eventos_title {
  display: flex;
  /* border: red 2px solid; */
  padding: 10px;
  margin-bottom: 30px;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  width: 80vw;
  margin: 0 auto;
  /* border: red 2px solid; */
}
.botao_slide {
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  border-color: rgb(0, 151, 255);
  color: rgb(0, 151, 255);
  background-color: transparent;
  padding: 16px 24px;
  font-size: 14px;
  text-transform: uppercase;
}
.title {
  /* border: red 2px solid; */
  color: #000;
}
.eventos h1 {
  display: block;
}
.eventos_items {
  background: #fff !important;
  border-radius: 8px;
  width: 250px;
  /* border: red 2px solid; */
  box-shadow: rgb(25 31 40 / 8%) 0px 6px 24px 0px;
  margin: 10px 10px 50px 10px;
  cursor: pointer;
}
.eventos .eventos_items:hover {
  box-shadow: rgb(25 31 40 / 20%) 0px 6px 24px 0px;
  background: #fff;
  border-radius: 8px;
  width: 250px;
  /* border: red 2px solid; */
}

.eventos .eventos_items img {
  border-radius: 8px;
  width: 100%;
  max-width: 250px;

  max-height: 300px;
}

@media (max-width: 992px) {
  .eventos .eventos_items {
    width: 300px;
  }

  .eventos {
    display: flex;
    /* border: red 2px solid; */
    padding: 10px;
    margin-bottom: 30px;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: 90vw;
    margin: 0 auto;
    /* border: red 2px solid; */
  }
  .eventos .eventos_items:hover {
    width: 300px;
    /* border: red 2px solid; */
  }
  .eventos .eventos_items img {
    border-radius: 8px;
    width: 100%;
    max-width: 300px;

    max-height: 300px;
  }
}
.eventos .eventos_items .content2 {
  padding: 10px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 16px;
  text-align: left;
  color: #d52b74;
  height: 100%;
  /* border: red 2px solid; */
}
.eventos .eventos_items .content2 h4 {
  color: #000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;

  text-transform: uppercase;
  font-weight: 600;
  font-size: 25px;
  margin-bottom: 6px;
  /* border: red 2px solid; */
}

.space {
  padding: 20px;
  /* border: red 2px solid; */
}
.card-pesquisar {
  /* border: red 2px solid; */
  font-size: 24px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 20px;
  width: 100%;
  height: 200px;
  /*  background-image: url("~@/assets/wave.svg");
  background-repeat: no-repeat;
  background-size: 100%; */
  /* Center child horizontally*/
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 10px;
}
.contentd input {
  text-align: center;
  border-color: #393939;
  border-radius: 8px;
}
.card-pesquisar svg {
  border-radius: 8px;
}
.card-pesquisar input {
  text-align: center;
  color: #fff;
  border-color: #fff;
  font-size: 20px;
  max-width: 490px;
  width: 100%;
  height: 42px;
  border-radius: 8px;
  top: 100px;
  display: flex;
}

.slide {
  /* border: red 2px solid; */
  box-shadow: rgb(25 31 40 / 15%) 0px 6px 24px 0px;
  color: #fff !important;

  border-radius: 8px;
}
.slide-item {
  border-radius: 14px;
  background-color: #fff;
  margin: 10px;
}

.slide .slide-item {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  align-items: center;
}
.slide .slide-item .image {
  /* border: red 2px solid; */

  width: 100%;
  border-radius: 8px;
}
.mission-info-text {
  display: none;
}
.sse {
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
}
@media (min-width: 992px) {
  .sse {
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    max-height: 350px;
  }
  .slided {
    width: 50%;
  }
  .mission-info-text {
    display: block;
  }
}
.slide .slide-item .image {
  width: 50%;
}
.slide .slide-item .content {
  width: 50%;
}

@media (max-width: 992px) {
  .slide .slide-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .slide .slide-item .content {
    width: 100%;
  }
}

#slider input[type="radio"] {
  display: none;
}
#slider label {
  cursor: pointer;
  text-decoration: none;
}
#slides {
}
#overflow {
  width: 100%;
  overflow: hidden;
}
#slide0:checked ~ #slides .inner {
  margin-left: 0;
}
#slide1:checked ~ #slides .inner {
  margin-left: 0;
}
#slide2:checked ~ #slides .inner {
  margin-left: -100%;
}
#slide3:checked ~ #slides .inner {
  margin-left: -200%;
}
#slide4:checked ~ #slides .inner {
  margin-left: -300%;
}
#slides .inner {
  transition: margin-left 800ms cubic-bezier(0.77, 0, 0.175, 1);
  width: 400%;
}
#slides .slide {
  width: 25%;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #fff;
}

#controls {
  margin: -180px 0 0 0;
  width: 100%;
  height: 50px;
  z-index: 3;
  position: relative;
}
#controls label {
  transition: opacity 0.2s ease-out;
  display: none;
  width: 50px;
  height: 50px;
  opacity: 0.4;
}
#controls label:hover {
  opacity: 1;
}
#slide1:checked ~ #controls label:nth-child(2),
#slide2:checked ~ #controls label:nth-child(3),
#slide3:checked ~ #controls label:nth-child(4),
#slide4:checked ~ #controls label:nth-child(1) {
  background: url(https://image.flaticon.com/icons/svg/130/130884.svg) no-repeat;
  float: right;
  margin: 0 -50px 0 0;
  display: block;
}
#slide1:checked ~ #controls label:nth-last-child(2),
#slide2:checked ~ #controls label:nth-last-child(3),
#slide3:checked ~ #controls label:nth-last-child(4),
#slide4:checked ~ #controls label:nth-last-child(1) {
  background: url(https://image.flaticon.com/icons/svg/130/130882.svg) no-repeat;
  float: left;
  margin: 0 0 0 -50px;
  display: block;
}
#bullets {
  margin: 150px 0 0;
  text-align: center;
}
#bullets label {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #ccc;
  margin: 0 10px;
}
#slide0:checked ~ #bullets label:nth-child(0),
#slide1:checked ~ #bullets label:nth-child(1),
#slide2:checked ~ #bullets label:nth-child(2),
#slide3:checked ~ #bullets label:nth-child(3),
#slide4:checked ~ #bullets label:nth-child(4) {
  background: #444;
}
@media screen and (max-width: 900px) {
  #slide1:checked ~ #controls label:nth-child(2),
  #slide0:checked ~ #controls label:nth-child(0),
  #slide2:checked ~ #controls label:nth-child(3),
  #slide3:checked ~ #controls label:nth-child(4),
  #slide4:checked ~ #controls label:nth-child(1),
  #slide1:checked ~ #controls label:nth-last-child(2),
  #slide0:checked ~ #controls label:nth-last-child(0),
  #slide2:checked ~ #controls label:nth-last-child(3),
  #slide3:checked ~ #controls label:nth-last-child(4),
  #slide4:checked ~ #controls label:nth-last-child(1) {
    margin: 0;
  }
  #slides {
    margin: 0 auto;
  }
}
</style>
