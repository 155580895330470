import Axios from "./Axios";
const API_URL = "/client/users";
import authHeader from './auth-header';

class AuthService {
  getDatauser() {
    return Axios.get(API_URL + '/userdados', { headers: authHeader() });
  }



  logout() {
    localStorage.removeItem("user");
  }

  register(user) {
    return Axios.post(API_URL + "/criarconta", {
      email: user.email,
      password: user.password,
      nome: user.nome,
      documento: user.documento,
      endereco: user.endereco,
      data_nasc: user.data_nasc,
      telefone: user.telefone,
    });
  }

  //REDEFINIR SENHA
  lostpass(user) {
    return Axios.post(API_URL + "lostpass", {
      username: user.username,
      email: user.email,
      password: user.password,
    });
  }

  lostpass_check(user) {
    return Axios.post(API_URL + "checkcode", {
      username: user.username,
      email: user.email,
      password: user.password,
    });
  }

  lostpass_redefinir(user) {
    return Axios.post(API_URL + "redefinir", {
      username: user.username,
      email: user.email,
      password: user.password,
    });
  }
}

export default new AuthService();
