<template>
 <div>
    <div class="container">
        <Form @submit="handleRegister" :validation-schema="schema">
        <div v-if="!successful">
          <div class="form-group">
            <label for="username">Username</label>
            <Field name="username" type="text" class="form-control" />
            <ErrorMessage name="username" class="error-feedback" />
          </div>
          <div class="form-group">
            <label for="email">Email</label>
            <Field name="email" type="email" class="form-control" />
            <ErrorMessage name="email" class="error-feedback" />
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <Field name="password" type="password" class="form-control" />
            <ErrorMessage name="password" class="error-feedback" />
          </div>

          <div class="form-group">
            <button class="btn btn-primary btn-block" :disabled="loading">
              <span
                v-show="loading"
                class="spinner-border spinner-border-sm"
              ></span>
              Criar
            </button>
          </div>
        </div>
      </Form>
    </div>

</div>
  </template>

  <script>
    import categoriasDataService from "../../../services/categoriasDataService";

  export default {
    name: "add-categorias",
    data() {
      return {
        categorias: {
          id: null,
          title: "",
          description: "",
          published: false,
        },
        submitted: false,
      };
    },
    methods: {
      saveEventos() {
        var data = {
          title: this.categorias.title,
          description: this.categorias.description,
        };

        categoriasDataService.create(data)
          .then((response) => {
            this.categorias.id = response.data.id;
            console.log(response.data);
            this.submitted = true;
          })
          .catch((e) => {
            console.log(e);
          });
      },

      newEventos() {
        this.submitted = false;
        this.categorias = {};
      },
    },
  };
  </script>

  <style>
  .submit-form {
    max-width: 300px;
  }
  </style>