<template>
  <div >
    <div  v-if="stepp < 2 && !expired">
            <h4 style="color: #000">Selecione um método de Pagamento</h4>
          </div>

    <div v-if="stepp == 1 && !expired" class="payment_info_card paddingCheckout">
      <div class="payment-simple p-LTR">
        <div id="payment-form ">
          <div class="p-CardForm">
            <div class="ipwarning" v-if="sandbox_warning">

              {{ translate("br", "sandbox_warning") }}
            </div>
          </div>

          <div
            class="p-PaymentMethodSelector spacebottom"
            role="tablist"
            aria-label="Formas de pagamento"
            aria-orientation="horizontal"
          >
            <span
              v-if="enabled_creditcard == 'yes'"
              @click="
                enabled_pix === 'yes' ? ifchangePaymentMethod('cc-form') : ''
              "
              :class="
                payment_select == 1 ? 'p-Tab--selected Tab--selected' : ''
              "
              id="cc-form"
              class="p-Tab Tab a_to_button"
            >
              <div class="p-TabIconContainer">
                <svg
                  viewBox="0 0 20 16"
                  xmlns="http://www.w3.org/2000/svg"
                  id="cc-form-svg"
                  role="presentation"
                  :class="
                    payment_select == 1
                      ? 'p-TabIcon--selected TabIcon--selected'
                      : ''
                  "
                  focusable="false"
                  class="p-Icon p-Icon--card Icon p-Icon--md p-TabIcon TabIcon"
                >
                  <path
                    fill-rule="evenodd"
                    d="M0 4a2 2 0 012-2h12a2 2 0 012 2H0zm0 2v6a2 2 0 002 2h12a2 2 0 002-2V6H0zm3 5a1 1 0 011-1h1a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clip-rule="evenodd"
                  ></path>
                </svg>

                <div class="p-FadeWrapper p-Tab-promoBadgeContainer"></div>
              </div>
              <span class="p-TabLabel TabLabel TabLabel--selected">
                {{ translate("br", "credcard") }}</span
              >
            </span>
            <span
              v-if="enabled_pix == 'yes'"
              @click="
                enabled_creditcard == 'yes'
                  ? ifchangePaymentMethod('pix-form')
                  : ''
              "
              id="pix-form"
              :class="
                payment_select == 2 ? 'p-Tab--selected Tab--selected' : ''
              "
              class="p-Tab Tab a_to_button"
            >
              <div class="p-TabIconContainer">
                <svg
                  viewBox="0 0 512 512"
                  id="pix-form-svg"
                  xmlns="http://www.w3.org/2000/svg"
                  role="presentation"
                  focusable="false"
                  :class="
                    payment_select == 2
                      ? 'p-TabIcon--selected TabIcon--selected'
                      : ''
                  "
                  class="p-Logo p-Logo--light p-Logo--md p-TabIcon TabIcon"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M242.4 292.5C247.8 287.1 257.1 287.1 262.5 292.5L339.5 369.5C353.7 383.7 372.6 391.5 392.6 391.5H407.7L310.6 488.6C280.3 518.1 231.1 518.1 200.8 488.6L103.3 391.2H112.6C132.6 391.2 151.5 383.4 165.7 369.2L242.4 292.5zM262.5 218.9C256.1 224.4 247.9 224.5 242.4 218.9L165.7 142.2C151.5 127.1 132.6 120.2 112.6 120.2H103.3L200.7 22.76C231.1-7.586 280.3-7.586 310.6 22.76L407.8 119.9H392.6C372.6 119.9 353.7 127.7 339.5 141.9L262.5 218.9zM112.6 142.7C126.4 142.7 139.1 148.3 149.7 158.1L226.4 234.8C233.6 241.1 243 245.6 252.5 245.6C261.9 245.6 271.3 241.1 278.5 234.8L355.5 157.8C365.3 148.1 378.8 142.5 392.6 142.5H430.3L488.6 200.8C518.9 231.1 518.9 280.3 488.6 310.6L430.3 368.9H392.6C378.8 368.9 365.3 363.3 355.5 353.5L278.5 276.5C264.6 262.6 240.3 262.6 226.4 276.6L149.7 353.2C139.1 363 126.4 368.6 112.6 368.6H80.78L22.76 310.6C-7.586 280.3-7.586 231.1 22.76 200.8L80.78 142.7H112.6z"
                  ></path>
                </svg>

                <div class="p-FadeWrapper p-Tab-promoBadgeContainer"></div>
              </div>
              <span class="p-TabLabel TabLabel">PIX</span>
            </span>
          </div>

          <div
            class="p-Grid p-CardForm spacebottom formulariob"
            v-if="payment_select == 1"
          >
            <form
              action="{{url de sua action}}"
              class="p-GridCell--12"
              method="POST"
              data-pagarmecheckout-form
            >
              <div
                :class="
                  documento_required
                    ? 'p-GridCell p-GridCell--12 p-GridCell--xs12 p-GridCell--md6'
                    : 'p-GridCell p-GridCell--12 p-GridCell--xs12 p-GridCell--md12'
                "
              >
                <div data-field="country" class="p-Field">
                  <label for="ip_installments" class="p-FieldLabel Label"
                    >{{ translate("br", "formtext5") }}
                    <span class="required">*</span></label
                  >

                  <div>
                    <div class="p-Select">
                      <select
                        name="parcelas"
                        id="ip_installments"
                        autocomplete="parcelas"
                        inputmode="text"
                        aria-invalid="false"
                        v-model="datapayment.parcelas.value"
                        aria-required="true"
                        class="Input p-Select-select"
                      >
                        <option disabled="" value="">Selecionar</option>

                        <option
                          v-for="(option, key) in parcelasList"
                          :value="option.parcela"
                          :key="key"
                        >
                          {{
                            option.parcela +
                            "x R$" +
                            option.valor_parcela.toFixed(2)
                          }}
                        </option>
                      </select>
                      <span data-pagarmecheckout-element="brand"></span>
                      <div
                        class="p-InputIcon c-InputPadding c-InputFontSize c-InputFontColor c-InputTransparentBorder"
                      >
                        <svg
                          viewBox="0 0 12 12"
                          xmlns="http://www.w3.org/2000/svg"
                          focusable="false"
                          role="presentation"
                          style="
                            width: 0.8em;
                            height: 0.8em;
                            margin-right: 15px;
                          "
                        >
                          <path
                            d="M10.193 3.97a.75.75 0 0 1 1.062 1.062L6.53 9.756a.75.75 0 0 1-1.06 0L.745 5.032A.75.75 0 0 1 1.807 3.97L6 8.163l4.193-4.193z"
                            fill="var(--colorIconChevronDown)"
                            fill-rule="evenodd"
                          ></path>
                        </svg>
                      </div>

                      <span
                        id="ip-error-4"
                        class="ip-error"
                        data-main="#ip_installments"
                        >{{ translate("br", "formtext_error1") }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="p-GridCell p-GridCell--12 p-GridCell--xs12 p-GridCell--md6"
                v-if="documento_required"
              >
                <div data-field="expiry" class="p-Field">
                  <label
                    class="p-FieldLabel Label Label--empty"
                    for="ip_docNumber"
                    >{{ translate("br", "formtext6") }}
                    <span class="required">*</span></label
                  >
                  <div>
                    <div class="p-Input">
                      <input
                        id="ip_docNumber"
                        @keyup="ipCreditMaskDate($event, ipDoc)"
                        v-model="datapayment.doc.value"
                        data-ip="card-holder-document"
                        type="text"
                        autocomplete="off"
                        name="infinitepay_custom[doc_number]"
                        maxlength="14"
                        placeholder="000.000.000-00"
                        class="p-Input-input Input Input--empty p-Input-input--textRight"
                      />
                      <span
                        id="ip-error-5"
                        class="ip-error"
                        data-main="#ip_docNumber"
                        >{{ translate("br", "formtext_error1") }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="p-GridCell p-GridCell--12 p-GridCell--md12">
                <div data-field="number" class="p-Field">
                  <label for="ip_ccNo" class="p-FieldLabel Label Label--empty">
                    {{ translate("br", "formtext")
                    }}<span class="required">*</span></label
                  >
                  <div>
                    <div class="p-CardNumberInput">
                      <div class="p-Input">
                        <input
                          id="ip_docNumber"
                          data-ip="card-holder-document"
                          v-model="datapayment.cardname.value"
                          autocomplete="off"
                          maxlength="35"
                          placeholder="NOME "
                          class="p-Input-input Input Input--empty p-Input-input--textRight"
                          type="text"
                          name="holder-name"
                          data-pagarmecheckout-element="holder_name"
                        />
                        <span
                          id="ip-error-1"
                          class="ip-error"
                          data-main="#ip_ccNo"
                          >{{ translate("br", "formtext_error1") }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="p-GridCell p-GridCell--12 p-GridCell--md12">
                <div data-field="number" class="p-Field">
                  <label for="ip_ccNo" class="p-FieldLabel Label Label--empty">
                    {{ translate("br", "formtext1")
                    }}<span class="required">*</span></label
                  >
                  <div>
                    <div class="p-CardNumberInput">
                      <div class="p-Input">
                        <input
                          id="ip_ccNo"
                          @keyup="(e) => ipCreditMaskDate(e, ipMcc)"
                          v-model="datapayment.cardnubner.value"
                          type="tel"
                          placeholder="0000 0000 0000 0000"
                          data-ip="card-number"
                          autocomplete="off"
                          maxlength="19"
                          name="card-number"
                          data-pagarmecheckout-element="number"
                          class="p-Input-input Input p-CardNumberInput-input Input--empty p-Input-input--textRight"
                          style="padding-right: 51.2px"
                        />
                        <span
                          id="ip-error-1"
                          class="ip-error"
                          data-main="#ip_ccNo"
                          >{{ translate("br", "formtext_error1") }}</span
                        >
                      </div>
                      <div class="p-CardIcons Input">
                        <div style="position: relative">
                          <div
                            class="p-CardBrandIcons p-CardBrandIcons--loaded p-CardBrandIcons--multiple p-CardBrandIcons--noMore"
                            aria-describedby="cardBrandIconsDesc"
                          >
                            <p
                              id="cardBrandIconsDesc"
                              class="u-visually-hidden"
                            >
                              Supported cards include visa, mastercard
                            </p>
                            <div class="p-CardBrandIcons-item">
                              <img
                                class="p-CardBrandIcon"
                                alt="visa"
                                :src="flag_visa"
                                v-if="flag == 'visa'"
                              />
                              <img
                                class="p-CardBrandIcon"
                                alt="mastercard"
                                :src="flag_master"
                                v-else-if="flag === 'mastercard'"
                              />
                              <img
                                class="p-CardBrandIcon"
                                alt="elo"
                                :src="flag_elo"
                                v-else-if="flag === 'elo'"
                              />
                              <img
                                class="p-CardBrandIcon"
                                alt="hipercard"
                                :src="flag_hipercard"
                                v-else-if="flag === 'hipercard'"
                              />
                              <img
                                class="p-CardBrandIcon"
                                alt="credit_card"
                                :src="flag_default"
                                v-else
                              />
                            </div>
                          </div>
                          <div class="p-CardKnownIcon">
                            <div
                              class="u-visually-hidden"
                              id="knownCardBrandDesc"
                            ></div>
                          </div>
                          <div class="p-CardErrorIcon">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="var(--colorIconCardError)"
                              role="presentation"
                            >
                              <path
                                opacity=".4"
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M15.337 4A5.5 5.5 0 1023 11.663V18a2 2 0 01-2 2H3a2 2 0 01-2-2V6a2 2 0 012-2h12.337zm6.707.293c.239.202.46.424.662.663a2.01 2.01 0 00-.662-.663zM3 9a1 1 0 011-1h5a1 1 0 011 1v3a1 1 0 01-1 1H4a1 1 0 01-1-1V9z"
                              ></path>
                              <path
                                opacity=".6"
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M4 15a1 1 0 100 2h1.5a1 1 0 100-2H4zm4.8 0a1 1 0 100 2h1.5a1 1 0 100-2H8.8zm3.8 1a1 1 0 011-1h1.5a1 1 0 110 2h-1.5a1 1 0 01-1-1zm5.9-1a1 1 0 100 2H20a1 1 0 100-2h-1.5z"
                              ></path>
                              0
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M18.5 14a5.5 5.5 0 110-11 5.5 5.5 0 010 11zm0-1.719a1.031 1.031 0 100-2.062 1.031 1.031 0 000 2.062zm0-7.906a.687.687 0 00-.688.688V8.5a.687.687 0 101.375 0V5.062a.687.687 0 00-.687-.687z"
                              ></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="p-GridCell p-GridCell--12 p-GridCell--xs6 p-GridCell--md6"
              >
                <div data-field="expiry" class="p-Field">
                  <label
                    for="cardExpirationMonth"
                    class="p-FieldLabel Label Label--empty"
                    >{{ translate("br", "formtext9")
                    }}<span class="required">*</span></label
                  >
                  <div>
                    <div class="p-Input">
                      <input
                        name="card-exp-month"
                        data-pagarmecheckout-element="exp_date"
                        id="cardExpirationMonth"
                        data-ip="card-expiration-month"
                        @keyup="ipCreditMaskDate($event, ipInteger1)"
                        v-model="datapayment.date.value"
                        type="tel"
                        autocomplete="off"
                        placeholder="MM/YYYY"
                        maxlength="7"
                        class="p-Input-input Input Input--empty p-Input-input--textRight"
                      />

                      <p
                        id="Field-expiryError"
                        class="p-FieldError Error"
                        role="alert"
                      >
                        {{
                          datapayment.mes.value && !datapayment.date.valid
                            ? datapayment.date.msg
                            : ""
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="p-GridCell p-GridCell--12 p-GridCell--xs6 p-GridCell--md6"
              >
                <div data-field="cvc" class="p-Field">
                  <label for="ip_cvv" class="p-FieldLabel Label Label--empty"
                    >{{ translate("br", "formtext4")
                    }}<span class="required">*</span></label
                  >
                  <div>
                    <div class="p-CardCvcInput">
                      <div class="p-Input">
                        <input
                          id="ip_cvv"
                          @keyup="ipCreditMaskDate($event, ipInteger3)"
                          v-model="datapayment.cvv.value"
                          type="tel"
                          data-ip="card-cvv"
                          autocomplete="off"
                          placeholder="CVV"
                          name="infinitepay_custom[cvv]"
                          :maxlength="cvvlength"
                          class="p-Input-input Input Input--empty p-Input-input--textRight"
                        />
                        <span
                          id="ip-error-3"
                          class="ip-error"
                          data-main="#ip_cvv"
                          >{{ translate("br", "formtext_error1") }}</span
                        >
                      </div>
                      <div class="p-CardCvcIcons Input">
                        <p id="cvcDesc" class="u-visually-hidden">
                          3-digit code on back of card
                        </p>
                        <div class="p-CardCvcIcons-group c-InputPadding">
                          <svg
                            class="p-CardCvcIcons-svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="var(--colorIconCardCvc)"
                            role="img"
                            aria-labelledby="cvcDesc"
                          >
                            <path
                              opacity=".2"
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M15.337 4A5.493 5.493 0 0013 8.5c0 1.33.472 2.55 1.257 3.5H4a1 1 0 00-1 1v1a1 1 0 001 1h16a1 1 0 001-1v-.6a5.526 5.526 0 002-1.737V18a2 2 0 01-2 2H3a2 2 0 01-2-2V6a2 2 0 012-2h12.337zm6.707.293c.239.202.46.424.662.663a2.01 2.01 0 00-.662-.663z"
                            ></path>
                            <path
                              opacity=".4"
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M13.6 6a5.477 5.477 0 00-.578 3H1V6h12.6z"
                            ></path>
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M18.5 14a5.5 5.5 0 110-11 5.5 5.5 0 010 11zm-2.184-7.779h-.621l-1.516.77v.786l1.202-.628v3.63h.943V6.22h-.008zm1.807.629c.448 0 .762.251.762.613 0 .393-.37.668-.904.668h-.235v.668h.283c.565 0 .95.282.95.691 0 .393-.377.66-.911.66-.393 0-.786-.126-1.194-.37v.786c.44.189.88.291 1.312.291 1.029 0 1.736-.526 1.736-1.288 0-.535-.33-.967-.88-1.14.472-.157.778-.573.778-1.045 0-.738-.652-1.241-1.595-1.241a3.143 3.143 0 00-1.234.267v.77c.378-.212.763-.33 1.132-.33zm3.394 1.713c.574 0 .974.338.974.778 0 .463-.4.785-.974.785-.346 0-.707-.11-1.076-.337v.809c.385.173.778.26 1.163.26.204 0 .392-.032.573-.08a4.313 4.313 0 00.644-2.262l-.015-.33a1.807 1.807 0 00-.967-.252 3 3 0 00-.448.032V6.944h1.132a4.423 4.423 0 00-.362-.723h-1.587v2.475a3.9 3.9 0 01.943-.133z"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="p-CardForm">
                <div class="alert alert-danger" v-if="msgserror_Card">
                  {{ msgserror_Card }}
                </div>
                <div class="alert alert-success" v-if="msgsuccess_Card">
                  {{ msgsuccess_Card }}
                </div>
              </div>
            </form>
          </div>
          <div
            class="p-Grid p-CardForm spacebottom"
            v-if="payment_select == 2"
            id="nick_infinity_pix-form"
          >
            <div class="resumocart">
              <div
                class="p-GridCell p-GridCell--12 p-GridCell--md12 instrucoes"
              >
                <p>{{ translate("br", "instructions_pix") }}</p>
                <div class="pix-label">
                  <img :src="pix_logo" alt="InfinitePay Label" />
                </div>

                <p v-if="valor_final != 0">
                  <strong v-if="discount_pix != 0">{{
                    translate("br", "discount") +
                    " (" +
                    discount_pix +
                    "%): " +
                    moneyformat +
                    " " +
                    (dados_final.valor_final -
                      (dados_final.valor_final / 100) * discount_pix)
                  }}</strong>

                  <strong>{{
                    translate("br", "total_value") +
                    " " +
                    moneyformat +
                    " " +
                    dados_final.valor_final.toFixed(2)
                  }}</strong>
                </p>

                <ul>
                  <li><span>1 - </span> {{ translate("br", "pixtext1") }}</li>
                  <li><span>2 - </span> {{ translate("br", "pixtext2") }}</li>
                  <li><span>3 - </span> {{ translate("br", "pixtext3") }}</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="submitForm">
            <div class="resumocart">
              <p>SubTotal: R$ {{ dados_final.valor_total.toFixed(2) }}</p>
              <p>Desconto: - R$ {{ dados_final.valor_desconto.toFixed(2) }}</p>
              <p>Taxas: + R$ {{ dados_final.valor_taxa.toFixed(2) }}</p>
              <p>Total: R$ {{ getTotal(1) }}</p>
            </div>
            <div class="alert alert-danger" v-if="msgserror_Pix">
              {{ msgserror_Pix }}
            </div>
            <div class="alert alert-success" v-if="msgsuccess_Pix">
              {{ msgsuccess_Pix }}
            </div>
            <button
              :disabled="isLoading"
              id="submit"
              class="btn btn-pink"
              @click="pagaragora(payment_select)"
            >
              <span v-if="isLoading" id="button-text">
                <div class="spinner" id="spinner"></div>
              </span>
              <span v-else id="button-text">
                {{ payment_select == 1 ? "Pagar agora" : "Gerar Pix" }}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <pay_order
      v-else-if="stepp == 2 && !expired"
      :qrcode="qrCodePix"
      :hora_f="dados_final.horario_final"
      :valor="getTotal(1)"
      :order_status="order_status"
      :keyqrcode="keyCodePix"
    ></pay_order>
    <div class="obrigadoClass" v-else-if="stepp == 3 ">
      <div class="container">
        <span
          ><img class="imagem_top" :src="apiData.cadastro.imagem" alt="img"
        /></span>
      </div>

      <div class="topofinish">
        <h4>
          {{ apiData.cadastro.titulo }}
        </h4>
        <ul class="mission-meta">
          <li>
            <i aria-hidden="true" class="fas fa-map-marker-alt"></i>
            {{
              apiData.endereco.endereco.cidade +
              " - " +
              apiData.endereco.endereco.estado
            }}
          </li>
          <li>
            <i aria-hidden="true" class="fas fa-calendar-alt"></i
            >{{ apiData.cadastro.data_i
            }}{{
              apiData.cadastro.data_f ? " > " + apiData.cadastro.data_f : ""
            }}
          </li>
          <li>
            <i class="far fa-clock"></i>{{ apiData.cadastro.hora_i }}
            {{ apiData.cadastro.hora_f ? " > " + apiData.cadastro.hora_f : "" }}
          </li>
        </ul>
        <h4>
          {{ apiData.cadastro.name }}
        </h4>
      </div>
      <div class="card">
        <div class="title_cart">
          <h3>Pedido efetuado com sucesso!</h3>

          <div class="numpedido">
            <h5>Nº DO PEDIDO:</h5>
            <h6>{{datagateway.uid_venda}}</h6>
          </div>
          <div class="numpedido">
            <h5>Pagamento:</h5>
            <h6>{{datagateway.tipo}}</h6>
            <h6>{{ gethora(datagateway.data) }} </h6>
          </div>
        </div>
        <div class="buttonclass">
          <router-link to="/conta/ingressos">
            <button class="btn btn-pink btn-long">
              <span>VER INGRESSOS</span>
            </button></router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import translate from "./translation.json";
import Payment from "payment";
import StringMask from "string-mask";
import "./assets/css/style.css";
import mastercard from "./assets/img/mastercard.svg";
import visa from "./assets/img/visa.svg";
import payment_logo_pix from "./assets/img/pix-106.svg";
import payment_logo from "./assets/img/logo.png";
import credit_card from "./assets/img/credit-card-solid.svg";
import VendasService from "../../services/vendas.service.js";
import pay_order from "./pay_order.vue";
import FinalizarService from "../../services/finalizar.service.js";

import firebase from "../../../firebase/firebaseInit";

export default {
  name: "PaymentSimple",

  data() {
    return {
      errorsend: "",
      msgserror_Card: "",
      msgsuccess_Card: "",
      msgserror_Pix: "",
      msgsuccess_Pix: "",
      datagateway: {uid_venda : "", tipo : "", data : ""},
      order_status: "processing",
      documento_required: false,
      stepp: 1,
      translation: translate,
      enabled_pix: "yes",
      isLoading: false,
      message: "",
      cvvlength: 3,
      enabled_creditcard: "yes",
      payment_select: 1,
      sandbox_warning: false,
      statusPagamento: "test",
      pix_logo: payment_logo_pix,
      infinity_logo: payment_logo,
      flag: "",
      flag_visa: visa,
      flag_master: mastercard,
      flag_default: credit_card,
      instructions: "",
      discount_pix: 0,
      moneyformat: "R$",
      datapayment: {
        cardname: {
          value: "",
          msg: "",
          valid: false,
        },
        date: {
          value: "",
          msg: "",
          valid: false,
        },
        cardnubner: {
          value: "",
          msg: "",
          valid: false,
        },
        tel: {
          value: "",
          msg: "",
          valid: false,
        },
        doc: {
          value: "",
          msg: "",
          valid: false,
        },
        mes: {
          value: "",
          msg: "",
          valid: false,
        },
        ano: {
          value: "",
          msg: "",
          valid: false,
        },
        cvv: {
          value: "",
          msg: "",
          valid: false,
        },
        parcelas: {
          value: "1",
          msg: "",
          valid: false,
        },
      },
      valor_final: 10,
      parcelasList: [],
      dados: [],
      qrCodePix: "",
      keyCodePix: "",
    };
  },
  mounted() {
    this.dados = this.dados_final;
    this.stepp = 1;
    this.resultParcelas();


  },
  created() {
    var tutorialsRef = firebase.database().ref("vendas");

    tutorialsRef
      .child(this.dados_final.refer_hash)
      .on("value", (snapshot) => {
        const data = snapshot.val()
        console.log('aaa..', snapshot.val());

        if(!data.gateway){

          return this.$emit("statuspedido", 3);

        }
         this.$emit("statuspedido", data.status);



        this.datagateway.uid_venda = data.uid_venda ? data.uid_venda : ""
        this.datagateway.tipo = data.gateway.metodo_gateway
        this.datagateway.data = data.gateway.updated_at
        console.log(snapshot.val());
        console.log("listen...");
        this.statusPagamento = data.status;

        this.order_status = this.statusPagamento == 5 ? "success" : "processing";

        if(this.statusPagamento == 5){
          setTimeout(() => {
                this.stepp = 3;
                this.$emit("statuspedido", 5);
                this.$emit("showfinish");
                this.$swal("Pagamento confirmado!", "Seu pagamento foi confirmado com sucesso!", "success");

              }, 2000);


        }

      });
  },
  methods: {
    gethora(hora){
      const event = new Date(hora);
const options = { dateStyle: 'short', timeStyle: 'short' };
const date = event.toLocaleString('pt-BR', options);

return date
    },
    getTotal(type) {
      const dados = this.dados_final;
      const taxas = dados.valor_taxa;
      const desconto = dados.valor_desconto;

      console.log("asdasd", dados);

      const subTotal = dados.valor_total;

      var valorTotalFinal = 0;

      if (type == 1) {
        const menosDesconto = subTotal - desconto;

        valorTotalFinal = menosDesconto + taxas;
      }

      return valorTotalFinal.toFixed(2);
    },
    async pagaragora(type) {
      var carrinhoparse = this.dados;
      var dados = {
        carrinho: carrinhoparse,
        gateway: {
          type_gateway: type,
          metodo_gateway: type === 1 ? "Cartão" : "Pix",
          response_gateway: "",
          parcelas_gateway: this.datapayment.parcelas.value,
          codigo_gateway: "",
          status_gateway: "",
        },
      };

      dados.datapayment = this.datapayment;

      if (type === 1) {
        const dda = {
          refer_hash: this.dados_final.refer_hash,

          card: {
            Holder: this.datapayment.cardname.value,
            CardNumber: this.datapayment.cardnubner.value,
            ExpirationDate: this.datapayment.date.value,
            SecurityCode: this.datapayment.cvv.value,
            InstallmentQuantity: this.datapayment.parcelas.value,
          },
        };

        await FinalizarService.enviarPagamentoCartao(dda).then(
          (response) => {
            var datafa = response.data;
            console.log("response", response);

            if (datafa.ResponseDetail.Status == 3) {
              this.msgsuccess_Card = datafa.ResponseDetail.Message;
              this.msgserror_Card = "";

              setTimeout(() => {
                this.stepp = 3;
                this.$emit("showfinish");
              }, 2000);
            } else {
              this.msgserror_Card = datafa.ResponseDetail.Message;
              this.msgsuccess_Card = "";
            }
          },

          (error) => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            this.msgserror_Card = "";
            this.msgsuccess_Card = error.response;
          }
        );
      } else if (type == 2) {
          const dda2 = {
          refer_hash: this.dados_final.refer_hash,

        };
        await FinalizarService.enviarPagamentoPix(dda2).then(
          (response) => {
            let datafd = response.data;
            console.log("response", response);

            this.stepp = 2;

            const qrdata = datafd.ResponseDetail
            console.log("qrcode", qrdata);

            this.qrCodePix = qrdata.QrCode ? qrdata.QrCode : "";
            this.keyCodePix = qrdata.Key;
            this.valor_final = this.getTotal(1);
            console.log("qrcode", this.qrCodePix);
            console.log("keyqrcode", this.keyCodePix);

            this.msgsuccess_Pix = "Pix gerado com sucesso, aguarde..."


            this.msgserror_Pix = ""
            this.order_status = "processing"

          },

          (error) => {
            console.log("response", error.response.data.erro);

            this.msgsuccess_Pix = ""

              this.msgserror_Pix = error.response.data.erro
          }
        );
      }
    },

    async resultParcelas() {
      const dados = { price: this.getTotal(1) };
      var dataf = [];

      await VendasService.simular(dados).then(
        (response) => {
          dataf = response.data;

          /*   this.payment_simulate =
            dataf.data_response.payment_methods[0].splittings; */
          this.parcelasList = dataf;
          console.log("response", dataf);
        },

        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );

      return dataf;
    },
    translate(locale, text) {
      var json = translate;

      var result = json[text];
      var result2 = result[locale];

      return result2;
    },
    ifchangePaymentMethod(frame) {
      if (this.enabled_creditcard == "yes" && this.enabled_pix == "yes") {
        if (frame == "cc-form") {
          this.payment_select = 1;
          document.getElementById("cc-form").classList.add("active");
          document.getElementById("pix-form").classList.add("noactive");

          document.getElementById("pix-form").classList.remove("active");
          document.getElementById("cc-form").classList.remove("noactive");
        } else if (frame == "pix-form") {
          this.payment_select = 2;
          document.getElementById("cc-form").classList.remove("active");
          document.getElementById("pix-form").classList.add("active");
          document.getElementById("cc-form").classList.add("noactive");

          document.getElementById("cc-form").classList.remove("active");
          document.getElementById("pix-form").classList.remove("noactive");
          document.getElementById("pix-form").classList.add("active");
        }
      }
    },
    setLoading(isLoading) {
      this.isLoading = isLoading;
      return isLoading;
    },
    ipCreditMaskDate(o, f) {
      var v_obj = o;

      f(v_obj.target.value, v_obj);
    },

    ipMcc(value) {
      if (this.ipIsMobile()) {
        this.datapayment.cardnubner.value = value;
      }

      value = value.replace(/\D/g, "");

      if (value.length > 12) {
        this.flag = Payment.fns.cardType(value);
        if (this.flag) {
          const result = Payment.getCardArray().find((e) => e.type == this.flag)
            .cvcLength[0];
          this.cvvlength == result ? "" : (this.datapayment.cvv.value = "");
          this.cvvlength = result;
        }
      } else {
        this.flag = "";
      }

      var result = StringMask.apply(value, "0000 0000 0000 0000");
      this.datapayment.cardnubner.value = result;
    },

    ipDoc(value) {
      value = value.replace(/\D/g, "");

      var result = StringMask.apply(value, "000.000.000-00");
      this.datapayment.doc.value = result;
    },

    ipDate(v) {
      v = v.replace(/\D/g, "");
      v = v.replace(/(\d{2})(\d)/, "$1/$2");
      v = v.replace(/(\d{2})(\d{2})$/, "$1$2");

      return v;
    },

    ipValidateMonthYear(result) {
      try {
        var date = result.split("/");

        const datep = { mes: date[0], ano: date[1], error: false };
        console.log(datep);
        if (datep.mes.length === 2 && datep.ano.length === 4) {
          var dateObj = new Date();
          var month = dateObj.getUTCMonth() + 1;

          var year = dateObj.getUTCFullYear();

          if (year === parseInt(datep.ano)) {
            if (parseInt(datep.mes) > 12) {
              datep.error = true;
              datep.msg = this.translate("br", "monthinvalid");
              return datep;
            }
            if (parseInt(datep.mes) >= month) {
              return datep;
            } else {
              datep.error = true;
              datep.msg = this.translate("br", "monthexpired");
              return datep;
            }
          } else if (parseInt(datep.ano) > year) {
            if (parseInt(datep.ano) < 2200) {
              return datep;
            } else {
              datep.error = true;
              datep.msg = this.translate("br", "dateinvalid");
              return datep;
            }
          } else {
            datep.error = true;
            datep.msg = this.translate("br", "yearexpired");
            return datep;
          }
        } else {
          return { error: true, msg: "date incomplete" };
        }
      } catch (e) {
        return { error: true, msg: "result split", e };
      }
    },

    ipInteger1(v) {
      var validate = this.ipValidateMonthYear(v);

      v = v.replace(/\D/g, "");

      var formatter = new StringMask("##/####");
      var result = formatter.apply(v);
      console.log(validate);
      if (validate.error) {
        this.datapayment.mes.value = validate.mes;
        this.datapayment.ano.value = validate.ano;
        this.datapayment.date.value = result;
        this.datapayment.date.msg = validate.msg;
        this.datapayment.date.valid = !validate.error;
      } else {
        this.datapayment.mes.value = validate.mes;
        this.datapayment.ano.value = validate.ano;
        this.datapayment.date.value = result;
        this.datapayment.date.msg = "";
        this.datapayment.date.valid = !validate.error;
      }
      console.log(this.datapayment.date);
    },
    ipInteger2(v) {
      var formatter = new StringMask("##");
      var result = formatter.apply(v);
      this.datapayment.ano.value = result;
    },
    ipInteger3(v) {
      const cvvtam = this.cvvlength;
      var mask = "";
      for (var i = 0; i < cvvtam; i++) {
        mask = mask + "#";
      }

      var formatter = new StringMask(mask);
      var result = formatter.apply(v);
      this.datapayment.cvv.value = result;
    },

    ipIsMobile() {
      try {
        document.createEvent("TouchEvent");
        return true;
      } catch (e) {
        return false;
      }
    },
  },
  components: {
    pay_order,
  },

  props: {
    dados_final: {
      required: true,
    },
    apiData: {
      required: true,
    },
    expired: {
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-location p {
  margin-bottom: 0;
}
.our-number {
  border-left: 1px solid #2d2d2d;
  border-bottom: 1px solid #2d2d2d;
  padding: 8px 10px;
  max-width: 230px;
  text-align: right;
}

input {
  color: #000 !important;
}
.our-number p:first-child,
.our-number p.small {
  color: #605f5f;
  font-size: 11px;
  margin-bottom: 0;
}

.payment-location {
  border-left: 1px solid #2d2d2d;
  border-bottom: 1px solid #2d2d2d;
  padding: 8px 10px;
}

.payment-location p:first-child,
.payment-location p.small {
  color: #605f5f;
  font-size: 11px;
}
.recipient:not(:last-child),
.intermediary:not(:last-child) {
  margin-right: 10px;
}
.iperror {
  padding: 10px;
  background-color: #ff0000;
  color: #fff;
  margin-bottom: 15px;
}

.title_cart {
  display: inline-block;
  margin: 30px;
}

.imagem_top {
  border-radius: 25px;
  object-fit: contain;
  width: 100%;
  max-height: 500px;
}
.ipsuccess {
  padding: 10px;
  background-color: #0fcd08;
  color: #fff;
  margin-bottom: 15px;
}
.recipient,
.intermediary {
  border-left: 1px solid #2d2d2d;
  border-bottom: 1px solid #2d2d2d;
  padding: 8px 10px;
}
.recipient p:first-child,
.intermediary p:first-child,
.recipient p.small,
.intermediary p.small {
  color: #605f5f;
  font-size: 11px;
}

.recipient,
.intermediary {
  border-left: 1px solid #2d2d2d;
  border-bottom: 1px solid #2d2d2d;
  padding: 8px 10px;
}

.recipient p:first-child,
.intermediary p:first-child,
.recipient p.small,
.intermediary p.small {
  color: #605f5f;
  font-size: 11px;
}
.due-date,
.value {
  border-left: 1px solid #2d2d2d;
  border-bottom: 1px solid #2d2d2d;
  padding: 8px 10px;
  max-width: 180px;
  text-align: right;
}
.obrigadoClass {
  color: #000 !important;
}
.obrigadoClass h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: #000 !important;
}
.obsvenda {
  margin: 0;
  color: #636466 !important;
  font-weight: 500;
  display: block;
}
.due-date p:first-child,
.value p:first-child,
.due-date p.small,
.value p.small {
  color: #605f5f;
  font-size: 11px;
}
.client {
  display: flex;
  gap: 16px;
  border-left: 1px solid #2d2d2d;
  border-bottom: 1px solid #2d2d2d;
  padding: 8px 10px;
}
.client p:first-child,
.client p.small {
  color: #605f5f;
  font-size: 11px;
}
.value {
  border-left: 1px solid #2d2d2d;
  border-bottom: 1px solid #2d2d2d;
  padding: 8px 10px;
  max-width: 180px;
  text-align: right;
}
.due-date p:first-child,
.value p:first-child,
.due-date p.small,
.value p.small {
  color: #605f5f;
  font-size: 11px;
}

.barcode {
  text-align: center;
}

.print,
.copy {
  text-align: center;
  white-space: nowrap;
  padding-top: 5px;
  margin-bottom: 15px;
}

.copy button {
  background: #004cff;
  box-shadow: 0 5px #004cff;
  color: #fff;
  padding: 12px 60px;
  font-size: 18px;
}

.print button {
  background: #e03e3e;
  box-shadow: 0 5px #5f1818;
  color: #fff;
  padding: 12px 60px;
  font-size: 18px;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

.copyButton {
  padding: 10px;
  color: #d52b74;
  border: 1px solid #d52b74;
  border-radius: 10px;
}

.copyButton i {
  color: #d52b74;
}

#qrcodepixcontent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
  border-radius: 8px;
  padding: 1rem;
  align-content: flex-start;
  flex-wrap: nowrap;
}

.resumocart {
  background-color: #fff;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  margin: 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-content: center;
  padding: 15px 20px 0px 0px;
  flex-wrap: nowrap;
}

.resumocart p {
  color: #000 !important;
}

@media only screen and (max-width: 600px) {
  #qrcodepixcontent {
    display: block;
  }
}
.topofinish {
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.obrigadoClass .buttonclass {
  margin: 30px;
}
.obrigadoClass .container {
  margin-bottom: 20px;
}
</style>
